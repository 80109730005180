<template>
  <div
    class="InvoiceDetailsSidebar"
    data-cy="invoice-details-sidebar"
    id="Testing__InvoiceDetailsSidebar"
  >
    <transition
      mode="out-in"
      name="Transition__fade"
    >
      <invoice-details-sidebar-base
        v-if="baseDisplayState"
        @editing="$emit('editing')"
        @trigger-flow="triggerFlow"
      />
      <invoice-details-sidebar-approve
        v-else-if="approving"
        @cancel="cancelFlow"
        @trigger-client-bank-error-flagging-flow="triggerFlagFlowForClientBankError($event)"
      />
      <invoice-details-sidebar-decline
        v-else-if="declining"
        @cancel="cancelFlow"
      />
      <invoice-details-sidebar-flag
        v-else-if="flagging"
        @cancel="cancelFlow"
        :note="note"
        :reason="reason"
      />
      <invoice-details-sidebar-notify
        v-else-if="notifying"
        @cancel="cancelFlow"
        @notified="cancelFlow"
      />
    </transition>
  </div>
</template>

<script>
// Helpers
import {
  Balance,
  ClientBankAccount,
  Invoice
} from '../../../utils/api'
import { goToNextInvoice } from '../../../utils/helpers'
// Components
import InvoiceDetailsSidebarApprove from './invoice-details-sidebar-approve.vue'
import InvoiceDetailsSidebarBase from './invoice-details-sidebar-base.vue'
import InvoiceDetailsSidebarDecline from './invoice-details-sidebar-decline.vue'
import InvoiceDetailsSidebarFlag from './invoice-details-sidebar-flag.vue'
import InvoiceDetailsSidebarNotify from './invoice-details-sidebar-notify.vue'

export default {
  name: 'InvoiceDetailsSidebar',

  components: {
    InvoiceDetailsSidebarApprove,
    InvoiceDetailsSidebarBase,
    InvoiceDetailsSidebarDecline,
    InvoiceDetailsSidebarFlag,
    InvoiceDetailsSidebarNotify,
  },

  data () {
    return {
      approving: false,
      bankErrorMessaging: {
        reason: 'pending other',
        note: {
          inactive:
            // eslint-disable-next-line max-len
            'This client does not have an active bank account; therefore, invoices for the client cannot be processed',
          noWire:
            // eslint-disable-next-line max-len
            'This client does not have an active bank account that accepts wires; therefore, wire invoices cannot be processed for this client',
        },
      },
      declining: false,
      flagging: false,
      note: '',
      notifying: false,
      reason: null,
    }
  },

  computed: {
    baseDisplayState () {
      return (!this.approving && !this.declining && !this.flagging && !this.notifying)
    },

    dividerDisplayState () {
      return (
        !this.invoice.flagged
        && !this.invoice.possible_duplicate
        && !this.invoice.requires_verification
      )
    },

    invoice () {
      return this.$store.getters.invoice
    },
  },

  watch: {
    invoice (oldValue, newValue) {
      if (newValue.id !== oldValue.id) {
        this.resetData()
      }
    },
  },

  methods: {
    async approveInvoice () {
      this.progressStart()

      // Bank account check
      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1823
      if (!await this.preApprovalBankChecking()) return

      // Update invoice to be approved and go to next invoice in line
      await this.updateInvoice()
    },

    cancelFlow () {
      // Catch all instead of using a param to target a single one
      this.approving = false
      this.declining = false
      this.flagging = false
      this.notifying = false
    },

    async checkApprovedFlowReadyStatus () {
      /*
       * if:
       * client.balance >= 0 (not a negative balance)
       * && debtor noa_emails exists
       * && delivery methods exist
       *
       * Go ahead and approve the invoice WITHOUT taking the user to the approve flow
       */
      let ready = false
      try {
        const balance = (await Balance.getClientTotalBalance(this.invoice.client.id)).data.total
        if (balance >= 0) {
          // Have to check for existence, as well (backend use-case, I guess)
          if (this.invoice.debtor.noa_emails !== null && this.invoice.debtor.noa_emails.length) {
            if (
              // Full address is required for required_copies
              (
                this.invoice.debtor.require_copies
                && this.invoice.debtor.address
                && this.invoice.debtor.city
                && this.invoice.debtor.state
                && this.invoice.debtor.zip
              )
              // Full address is required for required_originals
              || (
                this.invoice.debtor.require_originals
                && this.invoice.debtor.address
                && this.invoice.debtor.city
                && this.invoice.debtor.state
                && this.invoice.debtor.zip
              )
              // Online submit + value
              || (
                this.invoice.debtor.require_online_submit
                && this.invoice.debtor.online_submit_url
              )
              // Fax + value
              || (this.invoice.debtor.require_fax && this.invoice.debtor.fax)
              // Email + value + value.length
              || (
                this.invoice.debtor.require_email
                && this.invoice.debtor.invoice_delivery_emails
                && this.invoice.debtor.invoice_delivery_emails.length
              )
            ) {
              // Approve invoice (invoice update)
              ready = true
            } else {
              this.CLog('%c AUTO-APPROVE FAILED: delivery requirements', 'color:orange;')
            }
          } else {
            this.CLog('%c AUTO-APPROVE FAILED: noa email', 'color:orange;')
            this.alertInfo('A debtor NOA email does not exist')
          }
        } else {
          this.CLog('%c AUTO-APPROVE FAILED: client has a negative balance', 'color:orange;')
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar "Check Approved Ready Status"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            ready,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue getting the client\'s balance',
          title: 'Request error',
        })
      }
      finally {
        // eslint-disable-next-line no-unsafe-finally
        return ready
      }
    },

    async preApprovalBankChecking () {
      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1823
      try {
        const activeBank = (await ClientBankAccount.queryList({
          client_id: this.invoice.client.id,
        })).data.rows.filter(bank => bank.status === 'active')[0]

        // A client without an active bank account cannot have approved invoices
        if (!activeBank) {
          this.triggerFlagFlowForClientBankError('inactive')
          return false
        }

        // Must check the client's active bank to ensure that if this invoice is a wire, the client
        // has an active bank able to accept a wire
        if (this.invoice.transfer_type === 'wire' && !activeBank.confirmed_wire) {
          this.triggerFlagFlowForClientBankError('noWire')
          return false
        }

        return true
      }
      catch (error) {
        this.captureSentryEvent(
          'Approve invoice "Check Active Bank"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue checking the client\'s active bank account'
        })
      }
    },

    resetData () {
      Object.assign(this.$data, this.$options.data())
    },

    triggerFlagFlowForClientBankError (type) {
      // ['inactive', 'noWire'] = options for param `type`
      // This sets a specific message for a toastr depending on the type of bank error
      // After throwing a toastr, it will trigger the flag flow with the flag reason and note
      // pre-filled for a more streamlined UX
      // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1823
      const toastrMessage = type === 'inactive'
        ? 'This client does not have an active bank account'
        : 'This client does not have an active bank account that can accept wires'
      this.alertWarning(
        toastrMessage,
        'Invoice cannot be processed'
      )
      console.log(type)
      this.note = this.bankErrorMessaging.note[type]
      this.reason = this.bankErrorMessaging.reason
      this.triggerFlow('flagging')
      this.progressFinish()
    },

    async triggerFlow (flow) {
      // Reset all flows
      this.approving = false
      this.declining = false
      this.flagging = false
      this.notifying = false

      if (flow === 'approving') {
        if (await this.checkApprovedFlowReadyStatus()) {
          await this.approveInvoice()
          return
        }
      }
      this[flow] = true
    },

    async updateInvoice () {
      try {
        await Invoice.update({
          approved_invoice_adjustment_amount: 0,
          id: this.invoice.id,
          status: 'approved'
        })
        this.requestSuccess({ message: 'Invoice auto-approved' })
        await goToNextInvoice(
          this.invoice,
          this.$router,
          (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
            message: 'There are no more invoices to review. How would you like to proceed?',
            showNext,
            showPrevious,
          }),
          'forward'
        )
        this.progressFinish()
      }
      catch (error) {
        this.CError(error)
        if (error.message === 'Not allowed to send wires under $15.00') {
          this.requestFailure({
            message: 'The invoice amount - factor fee is less than $15.00. Wires must be greater than $15.00'
          })
        } else {
          this.captureSentryEvent(
            'Sidebar "Approve Invoice"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.requestFailure({ message: 'There was an issue updating the invoice' })
        }
      }
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsSidebar
  $block: &
  height: 100%
  padding: 0 rem(32px) 0 0
  max-width: rem(426px)
  width: 100%
</style>
