<template>
  <div class="InvoiceDetailsNavigation row row--align-center">
    <a
      @click="goBack"
      class="
        InvoiceDetailsNavigation__go-back-link row row--align-center fc-blue fs-20
        fw-med capitalize ml-15
      "
    >
      <i class="fa fa-back fc-light fa-15" /> Pending Invoices
    </a>


    <div class="row row--align-center row--justify-end">
      <button
        @click="getPreviousInvoice"
        class="InvoiceDetailsNavigation__arrow-btn"
        :disabled="disabledNavigation || navigating"
      >
        <div class="InvoiceDetailsNavigation__arrow-btn--left" />
      </button>

      <button
        @click="getNextInvoice"
        class="InvoiceDetailsNavigation__arrow-btn"
        :disabled="disabledNavigation || navigating"
      >
        <div class="InvoiceDetailsNavigation__arrow-btn--right" />
      </button>
    </div>
  </div>
</template>

<script>
// Packages
import _ from 'lodash'
// Helpers
import { goToNextInvoice } from '../../../utils/helpers'

export default {
  name: 'InvoiceDetailsNavigation',

  props: {
    // Passed when search in LS is null/undefined (from 'search-is-null' in goToNextInvoice)
    disabledNavigation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data () {
    return {
      navigating: false,
    }
  },

  computed: {
    invoice () {
      return this.$store.getters.invoice
    },
  },

  methods: {
    getNextInvoice () {
      return _.debounce(
        async () => {
          this.navigating = true
          // Try...Catch Prevents error from permanently disabling navigation arrows
          try {
            await goToNextInvoice(
              this.invoice.id,
              this.$router,
              (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
                message: 'There are no more invoices to review. How would you like to proceed?',
                showNext,
                showPrevious,
              }),
              'forward'
            )
          }
          catch (error) {
            this.CError(error)
          }
          this.navigating = false
        },
        250
      )()
    },

    getPreviousInvoice () {
      return _.debounce(
        async () => {
          this.navigating = true
          // Try...Catch Prevents error from permanently disabling navigation arrows
          try {
            await goToNextInvoice(
              this.invoice.id,
              this.$router,
              (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
                message: 'There are no more invoices to review. How would you like to proceed?',
                showPrevious,
                showNext,
              }),
              'backward'
            )
          }
          catch (error) {
            this.CError(error)
          }
          this.navigating = false
        },
        250
      )()
    },

    goBack () {
      const lsSearch = JSON.parse(localStorage.getItem('search'))
      window.localStorage.removeItem('search')
      if (!lsSearch) {
        this.$router.push({ name: 'pending' })
        return
      }
      this.$router.push(`/invoices/pending?${lsSearch.urlString}`)
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsNavigation
  padding: rem(25px) 0 rem(20px)

  &__arrow-btn
    padding: rem(15px)

    &[disabled],
    &[aria-disabled]
      cursor: default !important
      opacity: 0.6 !important

    &--left
      border-bottom: rem(5px) solid transparent
      border-right: rem(5px) solid $text-middle
      border-top: rem(5px) solid transparent
      margin-right: rem(9px)

    &--right
      border-bottom: rem(5px) solid transparent
      border-left: rem(5px) solid $text-middle
      border-top: rem(5px) solid transparent
      margin-left: rem(9px)

  &__button
    margin: 0 1rem

  &__go-back-link
    .fa-back
      margin-right: rem(30px)
</style>
