<template>
  <div class="InvoiceDetailsSidebarFlags">
    <!-- INACTIVE CLIENT && BUYOUT FLAGS -->
    <div
      v-if="!invoice.client.is_active || invoice.is_buyout"
      class="row row--wrap mb-25"
    >
      <div
        v-if="!invoice.client.is_active"
        class="
          InvoiceDetailsSidebarFlags__flag-wrapper row row--align-center row--justify-center
          row--width-auto bg-yellow mr-6 mb-6
        "
      >
        <label
          class="
            InvoiceDetailsSidebarFlags__flag-lbl InvoiceDetailsSidebarFlags__flag-lbl--inactive
            fc-white fs-12 bg-yellow uppercase
          "
        >
          Not Active
        </label>
      </div>
      <div
        v-if="invoice.is_buyout"
        class="
          InvoiceDetailsSidebarFlags__flag-wrapper row row--align-center row--justify-center
          row--width-auto bg-yellow mr-6 mb-6
        "
      >
        <label
          class="
            InvoiceDetailsSidebarFlags__flag-lbl InvoiceDetailsSidebarFlags__flag-lbl--inactive
            fc-white fs-12 bg-yellow uppercase
          "
        >
          Buyout
        </label>
      </div>
    </div>

    <!-- FLAGGED -->
    <div
      v-if="(currentFlags && invoice.flagged)"
      class="column mb-25"
    >
      <label class="fc-light fs-12 uppercase mb-10">FLAGS</label>
      <div class="row row--wrap">
        <div
          v-for="(flag, index) in currentFlags"
          :key="index"
          @click="showSidebarNotify(flag)"
          class="
            InvoiceDetailsSidebarFlags__flag-wrapper row row--align-center row--justify-center
            row--width-auto bg-red mr-6 mb-6 clickable
          "
          role="button"
          tabindex="0"
        >
          <label class="InvoiceDetailsSidebarFlags__flag-lbl fc-white fs-12 uppercase">
            {{ flagDisplayName[flag] || flag }}
          </label>
        </div>
      </div>
    </div>

    <!-- PREVIOUSLY FLAGGED -->
    <div
      v-if="previousFlags"
      class="column mb-25"
    >
      <label class="fc-light fs-12 uppercase mb-10">PREVIOUS FLAGS</label>
      <div class="row row--wrap">
        <label
          v-for="(flag, index) in previousFlags"
          :key="index"
          class="InvoiceDetailsSidebarFlags__previous-flag-lbl fs-12 uppercase bg-red mr-6 mb-6"
        >
          {{ flagDisplayName[flag] || flag }}
        </label>
      </div>
    </div>

    <hr
      v-if="(currentFlags && invoice.flagged) || (previousFlags && invoice.flagged)"
      class="Divider"
    >
  </div>
</template>

<script>

export default {
  name: 'InvoiceDetailsSidebarFlags',

  data () {
    return {
      flagDisplayName: {
        'approved other': 'other',
        'declined existing claim': 'existing claim',
        'declined low credit rating': 'low credit rating',
        'declined other': 'other',
        'declined pending claim': 'pending claim',
        'declined possible claim': 'possible claim',
        'paid other': 'other',
        'pending existing claim': 'existing claim',
        'pending low credit rating': 'pending low credit rating',
        'pending other': 'other',
        'pending possible claim': 'possible claim'
      },
    }
  },

  computed: {
    currentFlags () {
      if (!this.invoice.last_flag_message) return
      return this.invoice.last_flag_message.split(', ')
    },

    invoice () {
      return this.$store.getters.invoice
    },

    previousFlags () {
      if (!this.invoice.previous_flag_message) return null
      return this.invoice.previous_flag_message.split(', ')
    },
  },

  methods: {
    showSidebarNotify (flag) {
      this.$store.commit('STORE_SIDEBAR_NOTIFY_REASON', flag)
      this.$emit('show-sidebar-notify')
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsSidebarFlags
  $block: &

  // Between flag-lbl and flag-wrapper, we want a total height of 24px (including border)
  &__flag-lbl
    border: 1px solid $danger
    line-height: 1
    padding: rem(3px) rem(5px)

    &--inactive
      border: 1px solid $alert

  &__flag-wrapper
    padding: rem(2px) rem(2px)

    &:focus
      outline: none

      #{$block}__flag-lbl
        border-color: rgba(255, 255, 255, 0.6)

  &__previous-flag-lbl
    background-color: lighten(#A6A8AD, 28)
    color: $text-light
    display: inline-block
    padding: rem(3px) rem(7px)
</style>