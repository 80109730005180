var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InvoiceDetailsSidebarBase",
      attrs: { "data-cy": "invoice-details-sidebar-base" }
    },
    [
      _c("invoice-details-sidebar-flags", {
        on: {
          "show-sidebar-notify": function($event) {
            return _vm.triggerFlow("notifying")
          }
        }
      }),
      _c("div", { staticClass: "row mb-25" }, [
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
            _vm._v("CLIENT")
          ]),
          _c(
            "label",
            {
              staticClass: "fs-18 capitalize clickable",
              attrs: {
                "data-cy": "invoice-details-sidebar-base-client-name-lbl",
                tabindex: "0"
              },
              on: { click: _vm.goToClientProfile }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.invoice.client.shortened_name) +
                  "\n      "
              )
            ]
          ),
          _c("label", { staticClass: "fs-14 capitailze" }, [
            _vm._v("MC: " + _vm._s(_vm.invoice.client.mc))
          ]),
          _c("label", { staticClass: "fs-14 capitalize" }, [
            _vm._v(_vm._s(_vm.formatPhoneNumber(_vm.invoice.client.phone)))
          ])
        ]),
        _c("div", { staticClass: "row row--justify-end" }, [
          _c(
            "button",
            {
              staticClass: "bg-white fc-blue",
              on: {
                click: function($event) {
                  return _vm.$emit("editing")
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-edit fa-18 fc-blue" }),
              _vm._v("\n        Edit\n      ")
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "column mb-25" }, [
        _c(
          "div",
          { staticClass: "row row--align-center" },
          [
            _c("label", { staticClass: "fc-light fs-12 uppercase mr-5" }, [
              _vm._v("DEBTOR")
            ]),
            _c("base-debtor-rating", {
              attrs: {
                rating: _vm.invoice.debtor.display_rating,
                size: "small"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          {
            class: [
              "fs-18 capitalize clickable",
              {
                clickable:
                  _vm.invoice.debtor.id !== 2 &&
                  _vm.invoice.debtor.name.toLowerCase() !== "debtor not found"
              }
            ],
            attrs: { tabindex: "0" },
            on: { click: _vm.goToDebtorProfile }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.sanitizeAlgoliaNames(_vm.invoice.debtor_selected_name)
                ) +
                "\n    "
            )
          ]
        ),
        _c("label", { staticClass: "fs-14 capitailze" }, [
          _vm._v(
            _vm._s(_vm.invoice.debtor.city) +
              ", " +
              _vm._s(_vm.invoice.debtor.state)
          )
        ]),
        _c("label", { staticClass: "fs-14 capitalize" }, [
          _vm._v(_vm._s(_vm.formatPhoneNumber(_vm.invoice.debtor.phone)))
        ])
      ]),
      _c("div", { staticClass: "row mb-25" }, [
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
            _vm._v("LOAD #")
          ]),
          _c("label", { staticClass: "fs-18 capitalize" }, [
            _vm._v(_vm._s(_vm.invoice.load_number))
          ])
        ]),
        _c("div", { staticClass: "column" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
            _vm._v("AMOUNT")
          ]),
          _c("label", { staticClass: "fs-18 capitalize" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD"
                  }).format(_vm.invoice.amounts.total)
                ) +
                "\n      "
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "column mb-25" },
        [
          _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
            _vm._v("TRANSFER METHOD")
          ]),
          _vm.invoice.transfer_type === "wire"
            ? [
                _c("label", { staticClass: "fs-18 capitalize" }, [
                  _vm._v("\n        Wire\n      ")
                ])
              ]
            : _c("label", { staticClass: "fs-18 uppercase" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.invoice.transfer_type) + "\n    "
                )
              ])
        ],
        2
      ),
      !_vm.invoice.requires_verification
        ? [
            _c(
              "div",
              { staticClass: "row" },
              [
                _vm.invoice.debtor.id !== 2 &&
                _vm.invoice.debtor.name !== "debtor not found" &&
                _vm.invoice.client.is_active
                  ? _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mr-10",
                        attrs: {
                          "data-cy": "invoice-details-approve-btn",
                          disabled:
                            _vm.invoice.requires_verification ||
                            _vm.approveButtonDisabled
                        },
                        on: {
                          click: function($event) {
                            return _vm.triggerFlow("approving")
                          }
                        }
                      },
                      [_vm._v("\n        Approve\n      ")]
                    )
                  : _vm._e(),
                _c(
                  "base-button",
                  {
                    staticClass: "bg-light fc-white mr-10",
                    attrs: {
                      "data-cy": "invoice-details-decline-btn",
                      disabled: _vm.buttonsDisabled
                    },
                    on: {
                      click: function($event) {
                        return _vm.triggerFlow("declining")
                      }
                    }
                  },
                  [_vm._v("\n        Decline\n      ")]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "bg-white fc-light",
                    attrs: { disabled: _vm.buttonsDisabled },
                    on: {
                      click: function($event) {
                        return _vm.triggerFlow("flagging")
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-flag" }),
                    _vm._v("\n        Flag\n      ")
                  ]
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "bg-white fc-light ml-10",
                    attrs: {
                      "data-cy": "invoice-details-sidebar-base-verify-btn"
                    },
                    on: {
                      click: function($event) {
                        _vm.manualVerification = !_vm.manualVerification
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "fa fa-phone" }),
                    _vm._v("\n        Verify\n      ")
                  ]
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _c("div", { staticClass: "row mt-30" }, [
        _c(
          "button",
          {
            staticClass: "fc-light fs-14 uppercase",
            on: {
              click: function($event) {
                _vm.deleting = true
              }
            }
          },
          [
            _c("i", { staticClass: "fa fa-trash-can" }),
            _vm._v("\n      DELETE THIS INVOICE\n    ")
          ]
        )
      ]),
      _vm.deleting
        ? _c("base-item-delete", {
            attrs: { invoice: _vm.invoice },
            on: {
              cancel: function($event) {
                _vm.deleting = false
              },
              delete: _vm.deleteInvoice
            }
          })
        : _vm._e(),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__fade" } },
        [
          // Regardless of verification status and debtor ID, if the invoice is a possible
          // duplicate or someone has clicked 'verify', display the notification section.
          (_vm.invoice.requires_verification && _vm.invoice.debtor.id !== 2) ||
          _vm.invoice.possible_duplicate ||
          _vm.manualVerification
            ? _c(
                "div",
                [
                  _c("hr", { staticClass: "Divider" }),
                  _c("invoice-details-sidebar-notifications", {
                    attrs: { "manual-verification": _vm.manualVerification },
                    on: {
                      bypassed: function($event) {
                        _vm.manualVerification = false
                      },
                      "trigger-flag-flow": function($event) {
                        return _vm.triggerFlow("flagging")
                      },
                      verified: function($event) {
                        _vm.manualVerification = false
                      },
                      "waiting-for-response": function($event) {
                        _vm.manualVerification = false
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      !_vm.invoice.requires_verification &&
      !_vm.manualVerification &&
      !_vm.invoice.possible_duplicate
        ? _c("hr", { staticClass: "Divider" })
        : _vm._e(),
      _c("invoice-updates", {
        attrs: { stack: true, updates: _vm.invoice.updates }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }