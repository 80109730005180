var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InvoiceDetailsSidebar",
      attrs: {
        "data-cy": "invoice-details-sidebar",
        id: "Testing__InvoiceDetailsSidebar"
      }
    },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__fade" } },
        [
          _vm.baseDisplayState
            ? _c("invoice-details-sidebar-base", {
                on: {
                  editing: function($event) {
                    return _vm.$emit("editing")
                  },
                  "trigger-flow": _vm.triggerFlow
                }
              })
            : _vm.approving
            ? _c("invoice-details-sidebar-approve", {
                on: {
                  cancel: _vm.cancelFlow,
                  "trigger-client-bank-error-flagging-flow": function($event) {
                    return _vm.triggerFlagFlowForClientBankError($event)
                  }
                }
              })
            : _vm.declining
            ? _c("invoice-details-sidebar-decline", {
                on: { cancel: _vm.cancelFlow }
              })
            : _vm.flagging
            ? _c("invoice-details-sidebar-flag", {
                attrs: { note: _vm.note, reason: _vm.reason },
                on: { cancel: _vm.cancelFlow }
              })
            : _vm.notifying
            ? _c("invoice-details-sidebar-notify", {
                on: { cancel: _vm.cancelFlow, notified: _vm.cancelFlow }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }