<template>
  <transition name="Transition__opacity-fade">
    <div
      v-if="alert.visible"
      class="InvoiceDetailsAlertModal__modal"
    >
      <div class="InvoiceDetailsAlertModal__modal-bg" />
      <div class="InvoiceDetailsAlertModal__modal-content column">
        <label class="fs-20 fw-med mb-21">
          {{ alert.message }}
        </label>
        <div class="row mb-21">
          <base-button
            v-if="!alert.show.previous || !alert.show.next"
            @click="hideAlert"
            class="InvoiceDetailsAlertModal__modal-btn bg-light fc-white mr-10"
            ref="InvoiceDetailsAlertModal__default-btn"
          >
            Hide Alert
          </base-button>
          <base-button
            @click="exitInvoiceDetails"
            class="InvoiceDetailsAlertModal__modal-btn bg-white fc-blue mr-10"
          >
            Exit Invoice Details
          </base-button>
          <base-button
            v-if="alert.show.previous"
            @click="goToPreviousInvoice"
            :class="['InvoiceDetailsAlertModal__modal-btn fc-white', {
              'bg-light mr-10': alert.show.next,
              'bg-blue': !alert.show.next,
            }]"
            ref="InvoiceDetailsAlertModal__default-btn"
          >
            Previous Invoice
          </base-button>
          <base-button
            v-if="alert.show.next"
            @click="goToNextInvoice"
            :class="['InvoiceDetailsAlertModal__modal-btn bg-blue fc-white']"
            ref="InvoiceDetailsAlertModal__default-btn"
          >
            Next Invoice
          </base-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Helpers
import { goToNextInvoice } from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'

export default {
  name: 'InvoiceDetailsAlertModal',

  components: {
    BaseButton,
  },

  updated () {
    if (this.alert.visible) {
      this.$nextTick(() => {
        this.$refs['InvoiceDetailsAlertModal__default-btn'].$el.focus()
      })
    }
  },

  computed: {
    alert () {
      return this.$store.getters.alert
    },

    invoice () {
      return this.$store.getters.invoice
    }
  },

  methods: {
    exitInvoiceDetails () {
      const lsSearch = JSON.parse(localStorage.getItem('search'))
      window.localStorage.removeItem('search')
      this.$store.commit('HIDE_ALERT')
      if (!lsSearch) {
        this.$router.push({ name: 'pending' })
        return
      }
      this.$router.push(lsSearch.url)
    },

    async goToNextInvoice () {
      this.$store.commit('HIDE_ALERT')
      await goToNextInvoice(
        this.invoice.id,
        this.$router,
        (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
          message: 'The invoice is no longer pending. How would you like to proceed?',
          showNext,
          showPrevious,
        }),
        'forward'
      )
    },

    async goToPreviousInvoice () {
      this.$store.commit('HIDE_ALERT')
      await goToNextInvoice(
        this.invoice.id,
        this.$router,
        (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
          message: 'The invoice is no longer pending. How would you like to proceed?',
          showNext,
          showPrevious,
        }),
        'backward'
      )
    },

    hideAlert () {
      this.$store.commit('HIDE_ALERT')
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsAlertModal

  &__modal
    height: 100%
    left: 0
    position: fixed
    top: 0
    width: 100%
    z-index: 3

  &__modal-bg
    background-color: $text-light
    height: 100%
    opacity: 0.5
    position: absolute
    width: 100%

  &__modal-btn
    width: rem(170px)

  &__modal-content
    background-color: $white
    border-radius: $border-radius
    box-shadow: $modal-shadow
    margin: rem(176px) auto 0
    max-width: rem(540px)
    padding: rem(31px) rem(39px)
    position: relative
    width: 100%
    z-index: 4
</style>