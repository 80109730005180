<template>
  <div class="InvoiceDetailsSidebarNotify column">
    <label class="fs-18 mb-12">Notify client?</label>
    <base-textarea
      v-model.trim="message"
      class="width-100 mb-30"
      :label="true"
      :label-for="'message'"
      :rows="'5'"
    >
      Message
    </base-textarea>
    <div class="row row--align-center">
      <base-button
        @click="notify"
        @keydown.enter="notify"
        class="bg-blue fc-white mr-10"
      >
        Yes
      </base-button>
      <base-button
        @click="$emit('cancel')"
        @keydown.enter="$emit('cancel')"
        class="bg-light fc-white"
      >
        No
      </base-button>
    </div>
  </div>
</template>

<script>
// Helpers
import {
  Client,
  InvoiceUpdate
} from '../../../utils/api'
import { sanitizeAlgoliaNames } from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseTextarea from '../../../components/base-textarea.vue'

export default {
  name: 'InvoiceDetailsSidebarNotify',

  components: {
    BaseButton,
    BaseTextarea,
  },

  beforeMount () {
    this.message = this.configureBoilerplateNotifyMessage()
  },

  data () {
    return {
      message: null,
    }
  },

  computed: {
    invoice () {
      return this.$store.getters.invoice
    },

    reason () {
      return this.$store.getters.notifyReason
    },
  },

  methods: {
    configureBoilerplateNotifyMessage () {
      // If we don't disable, it will display all weird in the textarea
      // eslint-disable-next-line max-len
      return `Load ${this.invoice.load_number} (${this.sanitizeAlgoliaNames(this.invoice.debtor_selected_name)}) has been flagged for ${this.reason}. Please fix this invoice so there is no delay in payment. - Bobtail`
    },

    async notify () {
      this.progressStart()

      try {
        const contacts = (await Client.postMessage({
          id: this.invoice.client.id,
          message: this.message,
          subject: `Bobtail - Invoice Flagged Load# ${this.invoice.load_number}`
        })).data
        this.$store.commit('CLEAR_SIDEBAR_NOTIFY_REASON')

        // now create an invoice update
        if (
          (!contacts.phone_numbers || !contacts.phone_numbers.length)
          && (!contacts.emails || !contacts.emails.length)
        ) {
          this.progressFinish()
          this.alertWarning('There are no contacts with notifications enabled to send the notification to')
        } else {
          this.requestSuccess({ message: 'Message sent to the client!' })
          this.$emit('notified')
          let updateMessage = 'Flag notification sent via'
          if (contacts.phone_numbers.length) {
            updateMessage += ` text to ${contacts.phone_numbers.join(', ')}`
            if (contacts.emails.length) updateMessage += ' and'
          }
          if (contacts.emails.length) {
            updateMessage += ` email to ${contacts.emails.join(', ')}`
          }

          await InvoiceUpdate.create({
            invoice_id: this.invoice.id,
            notes: updateMessage,
            update_status: 'flag notification sent',
            update_type: 'note'
          })
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Notifications "Notify"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue notifying the client' })
      }
    },

    sanitizeAlgoliaNames,
  },
}
</script>