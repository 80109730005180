<template>
  <div class="InvoiceDetailsSidebarDecline">
    <transition
      mode="out-in"
      name="Transition__fade"
    >
      <div
        v-if="!notifyView && !declinedView"
        class="column"
        key="base"
      >
        <label class="InvoiceDetailsSidebarDecline__heading fs-18">
          Select Reason for Declining
        </label>
        <base-flag
          @toggle="$event.toggled ? reason = 'declined possible claim' : reason = null"
          :active="reason === 'declined possible claim'"
          data-cy="invoice-details-decline-possible-claim-flag"
        >
          Possible Claim
        </base-flag>
        <base-flag
          @toggle="$event.toggled ? reason = 'declined existing claim' : reason = null"
          :active="reason === 'declined existing claim'"
          data-cy="invoice-details-decline-existing-claim-flag"
        >
          Existing Claim
        </base-flag>
        <base-flag
          @toggle="$event.toggled ? reason = 'declined low credit rating' : reason = null"
          :active="reason === 'declined low credit rating'"
          data-cy="invoice-details-decline-low-credit-rating-flag"
        >
          Low Credit Rating
        </base-flag>
        <base-flag
          @toggle="$event.toggled ? reason = 'duplicate invoice' : reason = null"
          :active="reason === 'duplicate invoice'"
          data-cy="invoice-details-decline-duplicate-invoice-flag"
        >
          Duplicate Invoice
        </base-flag>
        <base-flag
          @toggle="$event.toggled ? reason = 'declined other' : reason = null"
          :active="reason === 'declined other'"
          data-cy="invoice-details-decline-other-flag"
        >
          Other
        </base-flag>

        <base-textarea
          v-show="reason"
          v-model.trim="note"
          class="width-100 mb-30"
          :label="true"
          :label-for="'notes'"
        >
          ADD NOTES?
        </base-textarea>
        <div class="row">
          <base-button
            @click="$emit('cancel')"
            class="bg-light fc-white mr-10"
            :disabled="buttonsDisabled"
          >
            Cancel
          </base-button>
          <base-button
            @click="decline"
            class="bg-blue fc-white"
            data-cy="invoice-details-decline-btn"
            :disabled="!reason || buttonsDisabled"
          >
            Decline Invoice
          </base-button>
        </div>
      </div>
      <div
        v-else
        key="flagged"
      >
        <transition
          mode="out-in"
          name="Transition__opacity-fade"
        >
          <invoice-details-sidebar-notify
            v-if="notifyView"
            @cancel="noNotify"
            @notified="notify"
            key="notify"
          />

          <div
            v-if="declinedView"
            class="column column--align-center"
            key="flagged"
          >
            <div class="InvoiceDetailsSidebarDecline__flag-mumbo-jumbo mb-23">
              <i class="fa fa-add fa-rotate-45 danger fa-25 fa--none" />
            </div>
            <label class="fs-18 mb-4">
              {{ notified ? 'Invoice declined and client notified' : 'Invoice Declined' }}
            </label>
            <div class="row row--justify-center">
              <base-button
                @click="goToNextInvoice"
                class="bg-blue fc-white mr-10"
              >
                Next Invoice
              </base-button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import { Invoice } from '../../../utils/api'
import { goToNextInvoice } from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseFlag from '../../../components/base-flag.vue'
import InvoiceDetailsSidebarNotify from './invoice-details-sidebar-notify.vue'
import BaseTextarea from '../../../components/base-textarea.vue'

export default {
  name: 'InvoiceDetailsSidebarDecline',

  components: {
    BaseButton,
    BaseFlag,
    InvoiceDetailsSidebarNotify,
    BaseTextarea,
  },

  data () {
    return {
      buttonsDisabled: false,
      declinedView: false,
      message: '',
      note: '',
      notified: false,
      notifyView: false,
      reason: null,
    }
  },

  computed: {
    invoice () {
      return this.$store.getters.invoice
    },
  },

  methods: {
    async decline () {
      this.buttonsDisabled = true
      this.progressStart()

      // Before updating, check to ensure the invoice isn't already declined
      try {
        const thisInvoice = (await Invoice.get(this.invoice.id)).data
        if (thisInvoice.status === 'declined') {
          // Only care about the last time the invoice was declined
          const declinedUpdate = thisInvoice.invoice_updates
            .filter(update => update.update_type === 'declined')[0]
          this.$store.commit('SET_ALERT', {
            message: `This invoice was already declined by ${declinedUpdate.user_name}
            at ${moment(declinedUpdate.updated_at).format('M/D/YY, h:mma')}`,
            showNext: true,
            showPrevious: true,
          })
          this.progressFinish()
          return
        }
        if (thisInvoice.status === 'approved') {
          const approvedUpdate = thisInvoice.invoice_updates
            .filter(update => update.update_status === 'invoice approved')[0]
          this.$store.commit('SET_ALERT', {
            message: `This invoice was already approved by ${approvedUpdate.user_name}
            at ${moment(approvedUpdate.updated_at).format('M/D/YY, h:mma')}`,
            showNext: true,
            showPrevious: true,
          })
          this.progressFinish()
          return
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Declined "Decline Invoice Check"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.progressFail()
        this.requestFailure('There was an issue checking current status of invoice', 'Request error')
      }

      try {
        await Invoice.update({
          id: this.invoice.id,
          notes: this.note,
          status: 'declined',
          update_status: this.reason,
        })
        // Store the selected flag reason for the notify view
        this.$store.commit('STORE_SIDEBAR_NOTIFY_REASON', this.reason)
        this.buttonsDisabled = false
        this.notifyView = true
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Decline "Decline Invoice Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue adding an update to the invoice' })
      }
    },

    async goToNextInvoice () {
      await goToNextInvoice(
        this.invoice.id,
        this.$router,
        (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
          message: 'There are no more invoices to review. How would you like to proceed?',
          showNext,
          showPrevious,
        }),
        'forward'
      )
    },

    notify () {
      this.notified = true
      this.declinedView = true
      this.notifyView = false
    },

    noNotify () {
      this.declinedView = true
      this.notifyView = false
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsSidebarDecline

  .BaseFlag
    margin-bottom: rem(20px)

  .fa-flag
    color: $branding
    font-size: rem(40px)

  &__flag-mumbo-jumbo
    +flex($align: center, $justify: center)
    background-color: #FCECEA
    border-radius: 50%
    height: rem(100px)
    width: rem(100px)

  &__heading
    margin-bottom: rem(50px)
</style>