<template>
  <div
    v-if="notes.length"
    @click="expanded = !expanded"
    class="InvoiceDetailsNoteAlert bg-yellow clickable width-100"
  >
    <div class="InvoiceDetailsNoteAlert__top-container row row--align-center row--justify-between">
      <label class="fc-dark fs-16 fw-med">
        {{
          notes.length
            ? 'Processing Notes'
            : 'There are no notes for this invoice'
        }}
      </label>
      <span class="fc-dark fs-16">
        {{
          expanded
            ? '[-]'
            : '[+]'
        }}
      </span>
    </div>
    <transition
      mode="out-in"
      name="Transition__fade"
    >
      <div
        v-if="expanded"
        class="InvoiceDetailsNoteAlert__bottom-container mt-23"
      >
        <div
          v-if="generalNotes.length"
          class="column"
        >
          <div
            v-for="(note, index) in generalNotes"
            :key="index"
            :class="['column', {
              'mb-16': specificNote || (index === 0 && generalNotes.length === 2)
            }]"
          >
            <h1 class="fs-15 fw-med">
              {{
                note.client ? note.client.shortened_name : note.debtor.name
              }}
            </h1>
            <div class="row row--align-center row--width-auto">
              <!-- DATE -->
              <time
                class="fc-light fs-14 no-wrap mr-10"
                :datetime="note.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
              >
                {{ note.created_at | datetime('MMM D, YYYY') }}
              </time>

              <div class="InvoiceDetailsNoteAlert__hr" />

              <!-- NOTE -->
              <label class="fs-15">{{ note.notes }}</label>
            </div>
          </div>
        </div>
        <div
          v-if="specificNote"
          class="column"
        >
          <div class="column">
            <h1 class="fs-15 fw-med">
              {{
                specificNote.client
                  ? `${specificNote.client.shortened_name} and ${specificNote.debtor.name}`
                  : specificNote.debtor.name
              }}
            </h1>
            <div class="row row--align-center row--width-auto">
              <!-- DATE -->
              <time
                class="fc-light fs-14 no-wrap mr-10"
                :datetime="specificNote.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
              >
                {{ specificNote.created_at | datetime('MMM D, YYYY') }}
              </time>

              <div class="InvoiceDetailsNoteAlert__hr" />

              <!-- NOTE -->
              <label class="fs-15">{{ specificNote.notes }}</label>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ProcessingNotes } from '../../../utils/api'

export default {
  name: 'InvoiceDetailsNoteAlert',

  async created () {
    await this.getNotes()
  },

  data() {
    return {
      expanded: false,
      notes: []
    }
  },

  props: {
    clientId: {
      type: String,
      required: true
    },
    debtorId: {
      type: String,
      required: true
    },
  },

  computed: {
    // For watcher
    changedProps () {
      const { clientId, debtorId } = this
      return {
        clientId,
        debtorId,
      }
    },

    generalNotes () {
      return this.notes.filter(note => !note.debtor || !note.client)
    },

    invoice () {
      return this.$store.getters.invoice
    },

    specificNote () {
      return this.notes.filter(note => note.debtor && note.debtor.id === this.debtorId)[0]
    },
  },

  watch: {
    async changedProps () {
      this.notes = [] // Prevents weird transition between different notes when changing invoices
      await this.getNotes()
    },
  },

  methods: {
    async getNotes () {
      try {
        // Get all notes with the respected client
        const clientGeneralNote = (await ProcessingNotes.queryList({
          client_id: this.clientId,
          debtor_id: 'null',
          status: 'active'
        })).data.rows
        const clientDebtorNotes = (await ProcessingNotes.queryList({
          client_id: this.clientId,
          debtor_id: this.debtorId,
          status: 'active'
        })).data.rows
        // Get a general note for the respected debtor
        const debtorGeneralNote = (await ProcessingNotes.queryList({
          debtor_id: this.debtorId,
          status: 'active',
          type: 'debtor', // Indicates it's a general note (debtors only)
        })).data.rows
        this.notes = [...clientGeneralNote, ...clientDebtorNotes, ...debtorGeneralNote]
      }
      catch (error) {
        this.captureSentryEvent(
          'Invoice Details Note Alert "Get Notes"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
      }
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsNoteAlert
  padding: rem(28px) rem(30px)

  &__hr
    background-color: $text-middle
    height: rem(26px)
    margin: 0 rem(19px)
    width: rem(1px)
</style>
