<template>
  <div
    class="InvoiceDetailsSidebarApprove"
    data-cy="invoice-details-sidebar-approve"
  >
    <transition
      mode="out-in"
      name="Transition__fade"
    >
      <div
        v-if="!approvedView"
        class="column"
        key="base"
      >
        <!-- Exists to display flag for debtor without delivery method -->
        <div
          v-if="!deliveryChecksValidation"
          class="row mb-25"
        >
          <label class="InvoiceDetailsSidebarApprove__flag-lbl bg-yellow fc-white fs-12 uppercase">
            Update delivery options
          </label>
        </div>

        <!-- CLIENT -->
        <div
          @click="goToClientProfile"
          :class="['column clickable', {
            'mb-25': clientBalance < 0
          }]"
          tabindex="0"
        >
          <label class="fc-light fs-12 uppercase">CLIENT</label>
          <label class="fs-18 capitalize">{{ invoice.client.shortened_name }}</label>
          <label class="fs-14 capitailze">MC: {{ invoice.client.mc }}</label>
          <label class="fs-14 capitalize">{{ formatPhoneNumber(invoice.client.phone) }}</label>
        </div>

        <template v-if="clientBalance < 0">
          <div class="InvoiceDetailsSidebarApprove__amounts-wrapper column">
            <!-- BALANCE -->
            <label class="fc-light fs-12 uppercase mb-12">BALANCE</label>
            <label
              :class="['fc-light fs-18 mb-27', { 'fc-red': clientBalance < 0 }]"
              data-cy="invoice-details-sidebar-approve-balance-lbl"
            >
              {{
                Intl
                  .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                  .format(balanceAmount)
              }}
            </label>

            <!-- APPROVED -->
            <div class="row row--align-center row--justify-between">
              <label class="fc-light fs-12 uppercase">APPROVED</label>
              <label
                class="fs-18"
                data-cy="invoice-details-sidebar-approve-approved-lbl"
              >
                {{
                  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                    .format(invoice.amounts.total)
                }}
              </label>
            </div>

            <!-- FACTOR FEE -->
            <div class="row row--align-center row--justify-between">
              <label class="fc-light fs-12 uppercase">Factor Fee</label>
              <label
                class="fs-18"
                data-cy="invoice-details-sidebar-approve-factor-fee-lbl"
              >
                {{
                  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                    .format(factorFee)
                }}
              </label>
            </div>

            <!-- DEDUCTION -->
            <div
              class="
                InvoiceDetailsSidebarApprove__deduction-input-wrapper
                row row--align-center row--justify-between
              "
            >
              <label class="fc-light fs-12 uppercase">DEDUCTION</label>
              <div class="row row--align-center row--width-auto">
                <span class="fs-18">- $</span>
                <input
                  v-model.trim="adjustment"
                  :class="['InvoiceDetailsSidebarApprove__deduction-input fs-18', {
                    'InvoiceDetailsSidebarApprove__deduction-input--error': !deductionValidation
                  }]"
                  data-cy="invoice-details-sidebar-approve-deduction-input"
                  placeholder="0"
                >
              </div>
            </div>

            <!-- FINAL -->
            <div class="row row--align-center row--justify-between">
              <label class="fc-light fs-12 uppercase">FINAL</label>
              <label
                class="fs-18"
                data-cy="invoice-details-sidebar-approve-final-lbl"
              >
                {{
                  Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                    .format(approvedAmount)
                }}
              </label>
            </div>

            <!-- CLIENT PAYMENT PLAN -->
            <div
              v-if="!!invoice.client.payment_plan_details"
              class="column mt-21"
            >
              <label class="fc-light fs-14">Payment Plan</label>
              <label class="fs-14">{{ invoice.client.payment_plan_details }}</label>
            </div>

            <!-- CHARGEBACKS -->
            <div
              v-if="pastWeekChargebacks.length"
              class="column mt-27"
            >
              <label class="fc-light fs-14 uppercase">Chargebacks this week</label>
              <div
                v-for="(chargeback, index) in pastWeekChargebacks"
                :key="index"
                class="row"
              >
                <time
                  class="fs-14 mr-12"
                  :datetime="chargeback.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
                >
                  {{ chargeback.created_at | datetime('ddd - MMM D, YYYY') }}
                </time>
                <label class="fs-14">
                  {{
                    Intl
                      .NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                      .format(chargeback.amount / 100)
                  }}
                </label>
              </div>
            </div>
            <div
              v-if="!pastWeekChargebacks.length"
              class="column mt-27"
            >
              <label class="fc-light fs-14 uppercase">No chargebacks this week</label>
            </div>
          </div>
        </template>


        <hr class="Divider">


        <!-- DEBTOR -->
        <div
          class="column mb-25"
          tabindex="0"
        >
          <div class="row row--align-center">
            <label class="fc-light fs-12 uppercase mr-5">DEBTOR</label>
            <base-debtor-rating
              :rating="invoice.debtor.display_rating"
              :size="'small'"
            />
          </div>
          <label
            @click="goToDebtorProfile"
            class="fs-18 capitalize clickable"
          >
            {{ sanitizeAlgoliaNames(invoice.debtor_selected_name) }}
          </label>
          <label class="fs-14 capitailze">{{ invoice.debtor.city }}, {{ invoice.debtor.state }}</label>
          <label class="fs-14 capitalize">{{ formatPhoneNumber(invoice.debtor.phone) }}</label>
        </div>

        <!-- DELIVERY METHODS -->
        <div class="column mb-25">
          <transition name="Transition__fade">
            <base-delivery-requirements
              v-if="currentDeliveryReqs"
              @save="setCurrentDeliveryReqs"
              :debtor="currentDeliveryReqs"
            />
          </transition>
        </div>

        <!-- BUTTONS -->
        <div class="row">
          <base-button
            @click="approve"
            class="bg-blue fc-white mr-10"
            :disabled="!validation || buttonsDisabled"
            data-cy="invoice-details-approve-btn"
          >
            Approve
          </base-button>
          <base-button
            @click="closeApproving"
            class="bg-light fc-white"
            :disabled="buttonsDisabled"
          >
            Cancel
          </base-button>
        </div>
      </div>

      <div
        v-else
        class="column column--align-center"
        key="flagged"
      >
        <div class="InvoiceDetailsSidebarFlag__flag-mumbo-jumbo mb-23">
          <i class="fa fa-check fa--none" />
        </div>
        <label class="fs-18 mb-4">Invoice approved</label>
        <div class="row row--justify-center">
          <base-button
            @click="goToNextInvoice"
            class="bg-blue fc-white mr-10"
            data-cy="invoice-details-sidebar-approve-next-invoice-btn"
          >
            Next Invoice
          </base-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable newline-per-chained-call */
// Packages
import moment from 'moment'
// Helpers
import {
  Balance,
  Client,
  ClientBankAccount,
  Debtor,
  Invoice
} from '../../../utils/api'
import {
  formatPhoneNumber,
  goToNextInvoice,
  sanitizeAlgoliaNames,
  transformStringNumberToNumber,
} from '../../../utils/helpers'
import BobtailMath from '../../../utils/bobtailMath'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseDebtorRating from '../../../components/base-debtor-rating.vue'
import BaseDeliveryRequirements from '../../../components/base-delivery-requirements.vue'
// Mixins
import {
  ValidationMixin
} from '../../../utils/validation-mixin'

export default {
  name: 'InvoiceDetailsSidebarApprove',

  components: {
    BaseButton,
    BaseDebtorRating,
    BaseDeliveryRequirements,
  },

  mixins: [ValidationMixin],

  async created () {
    // Get client balance and last chargeback
    try {
      this.clientBalance = (await Balance.getClientTotalBalance(this.invoice.client.id)).data.total

      const start = moment().tz('America/New_York').endOf('day').subtract(7, 'days').toDate()
      const end = moment().tz('America/New_York').endOf('day').toDate()

      this.pastWeekChargebacks = (await Client.getChargeBacksInRange({
        end,
        id: this.invoice.client.id,
        start,
      })).data.balances
    }
    catch (error) {
      this.captureSentryEvent(
        'Sidebar Approve "Created"',
        {
          config: error.config,
          data: this.$data,
          details: error,
          props: this.$props,
          response: error.response,
        }
      )
      this.CError(error)
      this.requestFailure({ message: 'There was an issue getting the client\'s total balance' })
    }
    // Set static approved amount for validation
    this.staticApprovedAmt = new BobtailMath(this.invoice.amounts.total)
      .subtract(this.factorFee)
      .subtract(this.adjustment)
      .pennies()
    this.setCurrentDeliveryReqs()
  },

  data () {
    return {
      adjustment: null,
      approvedView: false,
      buttonsDisabled: false,
      clientBalance: null, // penny value
      // Represents the default invoice debtor or the last saved delivery edit
      currentDeliveryReqs: null,
      deductionInputStyle: 'width: 11px',
      pastWeekChargebacks: [],
      staticApprovedAmt: null, // penny value
    }
  },

  computed: {
    approvedAmount () {
      return new BobtailMath(this.invoice.amounts.total)
        .subtract(this.factorFee)
        .subtract(this.adjustment)
        .dollars()
    },

    balanceAmount () {
      return new BobtailMath(this.clientBalance / 100)
        .add(this.adjustment)
        .dollars()
    },

    deductionValidation () {
      if (!this.adjustment) return true // Starts as 0
      if (!this.isValidAdjustment(this.adjustment.toString())) return false
      // Check for more than 2 decimals (cents) (if a decimal point exists)
      const decimalIndex = this.adjustment.indexOf('.')
      if (decimalIndex > -1) {
        const decimalSlice = this.adjustment.slice(this.adjustment.indexOf('.') + 1)
        if (decimalSlice.length > 2) return false
      }
      return true
    },

    deliveryChecksValidation () {
      if (!this.currentDeliveryReqs) return false
      const deliveryChecks = Object.values(this.currentDeliveryReqs)
      if (!deliveryChecks.includes(true)) {
        return false
      }
      return true
    },

    factorFee () {
      return new BobtailMath(this.invoice.amounts.total)
        .multiply(this.invoice.client.factor_rate_percentage / 100)
        .dollars()
    },

    invoice () {
      return this.$store.getters.invoice
    },

    validation () {
      let valid = true

      if (!this.deductionValidation) return false

      // There must be one delivery method checked
      valid = this.deliveryChecksValidation

      // x = final value before any deductions
      // y = static balance
      // a deduction <= min(x, y)
      const x = Math.abs(this.staticApprovedAmt)
      const y = Math.abs(this.clientBalance)
      const deduction = transformStringNumberToNumber(this.adjustment)
      if (deduction > Math.min(x, y)) {
        valid = false
      }

      return valid
    },
  },

  methods: {
    async approve () {
      this.progressStart()

      // Before approving, check if invoice is stilled of 'pending' status
      await this.checkInvoiceStatus()

      try {
        this.buttonsDisabled = true

        // Bank account check - Triggers an emit to invoice-details-sidebar.vue that will handle
        // the rest of the functionality requirements
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1823
        if (!await this.preApprovalBankCheck()) return

        await this.updateDebtor()
        await this.updateInvoice()

        // If client balance was negative and is now 0, update their payment plan to be null
        if (this.clientBalance < 0 && this.balanceAmount === 0) {
          await this.updateClientPaymentPlan()
        }

        this.approvedView = true
        this.progressFinish()
      }
      catch (error) {
        // Handle invoice approval but error sending email case
        // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1855
        if (
          (
            error.response
            && error.response.data
            && error.response.data.message.includes('Error Sending Email')
          )
          || (
            error.response
            && error.response.data
            && error.response.data.stack
            && error.response.data.stack.includes('10MB')
          )
        ) {
          // This is a direct copy of the final part of the TRY... statement directly above
          // If client balance was negative and is now 0, update their payment plan to be null
          if (this.clientBalance < 0 && this.balanceAmount === 0) {
            await this.updateClientPaymentPlan()
          }

          this.progressFinish()
          this.alertWarning('Invoice Approved but could not notify the debtor. Please email them manually')
          this.approvedView = true
        } else {
          this.captureSentryEvent(
            'Sidebar Approve "Debtor and Invoice Update"',
            {
              config: error.config,
              data: this.$data,
              details: error,
              props: this.$props,
              response: error.response,
            }
          )
          this.CError(error)
          this.requestFailure({ message: 'There was an issue submitting your request ' })
        }
        this.buttonsDisabled = false
      }
    },

    async checkInvoiceStatus () {
      try {
        const thisInvoice = (await Invoice.get(this.invoice.id)).data
        if (thisInvoice.status === 'declined') {
          // Only care about the last time the invoice was declined
          const declinedUpdate = thisInvoice.invoice_updates
            .filter(update => update.update_type === 'declined')[0]
          this.$store.commit('SET_ALERT', {
            message: `This invoice was already declined by ${declinedUpdate.user_name}
            at ${moment(declinedUpdate.updated_at).format('M/D/YY, h:mma')}`,
            showNext: true,
            showPrevious: true,
          })
          this.progressFinish()
          return
        }
        if (thisInvoice.status === 'approved') {
          const approvedUpdate = thisInvoice.invoice_updates
            .filter(update => update.update_status === 'invoice approved')[0]
          this.$store.commit('SET_ALERT', {
            message: `This invoice was already approved by ${approvedUpdate.user_name}
            at ${moment(approvedUpdate.updated_at).format('M/D/YY, h:mma')}`,
            showNext: true,
            showPrevious: true,
          })
          this.progressFinish()
          return
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Approve "Approve Invoice Check"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonsDisabled = false
        this.progressFail()
        this.requestFailure('There was an issue checking current status of invoice', 'Request error')
      }
    },

    closeApproving () {
      this.$emit('cancel')
    },

    formatPhoneNumber,

    goToClientProfile () {
      this.$router.push({
        name: 'client',
        params: {
          id: this.invoice.client.id.toString(),
        },
      })
    },

    goToDebtorProfile () {
      this.$router.push({
        name: 'debtor',
        params: {
          id: this.invoice.debtor.id.toString(),
        },
      })
    },

    async goToNextInvoice () {
      await goToNextInvoice(
        this.invoice.id,
        this.$router,
        (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
          message: 'There are no more invoices to review. How would you like to proceed?',
          showNext,
          showPrevious,
        }),
        'forward'
      )
    },

    async preApprovalBankCheck () {
      try {
        const activeBank = (await ClientBankAccount.queryList({
          client_id: this.invoice.client.id,
        })).data.rows.filter(bank => bank.status === 'active')[0]

        // A client without an active bank account cannot have approved invoices
        if (!activeBank) {
          this.progressFinish()
          this.$emit('trigger-client-bank-error-flagging-flow', 'inactive')
          return false
        }

        // Must check the client's active bank to ensure that if this invoice is a wire, the client
        // has an active bank able to accept a wire
        if (this.invoice.transfer_type === 'wire' && activeBank.confirmed_wire !== 'confirmed') {
          this.progressFinish()
          this.$emit('trigger-client-bank-error-flagging-flow', 'noWire')
          return false
        }

        return true
      }
      catch (error) {
        this.captureSentryEvent(
          'sidebar-approve "Check Active Bank"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue checking the client\'s active bank account'
        })
      }
    },

    sanitizeAlgoliaNames,

    setCurrentDeliveryReqs (methods = null) {
      // Methods are passed by the base-delivery-requirements component when a user clicks "save"
      // Methods represent the delivery options a debtor has, such as Invoice Delivery Emails

      // Initialize in created()
      if (!methods) {
        this.currentDeliveryReqs = {
          fax: this.invoice.debtor.fax,
          invoice_delivery_emails: this.invoice.debtor.invoice_delivery_emails,
          mailing_address: this.invoice.debtor.mailing_address,
          mailing_address_2: this.invoice.debtor.mailing_address_2,
          mailing_city: this.invoice.debtor.mailing_city,
          mailing_state: this.invoice.debtor.mailing_state,
          mailing_zip: this.invoice.debtor.mailing_zip,
          noa_emails: this.invoice.debtor.noa_emails,
          online_submit_url: this.invoice.debtor.online_submit_url,
          pay_status_emails: this.invoice.debtor.pay_status_emails,
          require_copies: this.invoice.debtor.require_copies,
          require_email: this.invoice.debtor.require_email,
          require_fax: this.invoice.debtor.require_fax,
          require_online_submit: this.invoice.debtor.require_online_submit,
          require_originals: this.invoice.debtor.require_originals,
        }
        return
      }

      // When methods are not null (base-delivery-requirements is emitting the saved values), update
      // currentDeliveryReqs (should be a mix of the last made edit and invoice debtor (backup))
      this.currentDeliveryReqs = {
        fax: methods.fax.required
          ? methods.fax.value
          : this.invoice.debtor.fax,
        invoice_delivery_emails: methods.invoice_delivery_emails.required
          ? methods.invoice_delivery_emails.value.toLowerCase().split(',').map(email => email.trim())
          : this.invoice.debtor.invoice_delivery_emails,
        mailing_address: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.address
          : this.invoice.debtor.mailing_address,
        mailing_address_2: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.address_2
          : this.invoice.debtor.mailing_address_2,
        mailing_city: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.city
          : this.invoice.debtor.mailing_city,
        mailing_state: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.state
          : this.invoice.debtor.mailing_state,
        mailing_zip: methods.mail.required.copies || methods.mail.required.originals
          ? methods.mail.value.zip
          : this.invoice.debtor.mailing_zip,
        noa_emails: methods.noa_emails.value.toLowerCase().split(',').map(email => email.trim()),
        online_submit_url: methods.online.required
          ? methods.online.value
          : this.invoice.debtor.online_submit_url,
        // Not required by base-delivery-requirements, must check for existing value not "required"
        pay_status_emails: methods.pay_status_emails.value
          ? methods.pay_status_emails.value.split(', ')
          : this.currentDeliveryReqs.pay_status_emails,
        require_copies: methods.mail.required.copies,
        require_email: methods.invoice_delivery_emails.required,
        require_fax: methods.fax.required,
        require_online_submit: methods.online.required,
        require_originals: methods.mail.required.originals,
      }
    },

    async updateClientPaymentPlan () {
      try {
        await Client.update({
          id: this.invoice.client.id,
          payment_plan_details: null,
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'sidebar-approve "Update Payment Plan"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue updating the client\'s payment plan'
        })
      }
    },

    async updateDebtor () {
      try {
        await Debtor.update({
          fax: formatPhoneNumber(this.currentDeliveryReqs.fax),
          id: this.invoice.debtor.id,
          invoice_delivery_emails: this.currentDeliveryReqs.invoice_delivery_emails,
          mailing_address: this.currentDeliveryReqs.mailing_address,
          mailing_address_2: this.currentDeliveryReqs.mailing_address_2,
          mailing_city: this.currentDeliveryReqs.mailing_city,
          mailing_state: this.currentDeliveryReqs.mailing_state,
          mailing_zip: this.currentDeliveryReqs.mailing_zip,
          noa_emails: this.currentDeliveryReqs.noa_emails,
          online_submit_url: this.currentDeliveryReqs.online_submit_url,
          pay_status_emails: this.currentDeliveryReqs.pay_status_emails,
          require_copies: this.currentDeliveryReqs.require_copies,
          require_email: this.currentDeliveryReqs.require_email,
          require_fax: this.currentDeliveryReqs.require_fax,
          require_online_submit: this.currentDeliveryReqs.require_online_submit,
          require_originals: this.currentDeliveryReqs.require_originals,
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'sidebar-approve "Update Debtor"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue updating the debtor\'s delivery requirements'
        })
      }
    },

    async updateInvoice () {
      try {
        await Invoice.update({
          approved_invoice_adjustment_amount: Math.abs(
            transformStringNumberToNumber(this.adjustment, { pennies: true, round: false })
          ) || 0,
          id: this.invoice.id,
          status: 'approved'
        })
      }
      catch (error) {
        this.captureSentryEvent(
          'sidebar-approve "Update Invoice"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue updating the invoice'
        })
      }
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsSidebarApprove

  .Divider
    margin: rem(48px) 0

  &__deduction-input
    border: none
    text-align: right
    width: rem(85px)

    &--error
      border: rem(1px) solid $danger

  &__deduction-input-wrapper
    border-bottom: rem(1px) dashed $borders

  &__flag-lbl
    padding: rem(3px) rem(5px)
</style>
