var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "InvoiceDetailsSidebarFlags" }, [
    !_vm.invoice.client.is_active || _vm.invoice.is_buyout
      ? _c("div", { staticClass: "row row--wrap mb-25" }, [
          !_vm.invoice.client.is_active
            ? _c(
                "div",
                {
                  staticClass:
                    "\n        InvoiceDetailsSidebarFlags__flag-wrapper row row--align-center row--justify-center\n        row--width-auto bg-yellow mr-6 mb-6\n      "
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "\n          InvoiceDetailsSidebarFlags__flag-lbl InvoiceDetailsSidebarFlags__flag-lbl--inactive\n          fc-white fs-12 bg-yellow uppercase\n        "
                    },
                    [_vm._v("\n        Not Active\n      ")]
                  )
                ]
              )
            : _vm._e(),
          _vm.invoice.is_buyout
            ? _c(
                "div",
                {
                  staticClass:
                    "\n        InvoiceDetailsSidebarFlags__flag-wrapper row row--align-center row--justify-center\n        row--width-auto bg-yellow mr-6 mb-6\n      "
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "\n          InvoiceDetailsSidebarFlags__flag-lbl InvoiceDetailsSidebarFlags__flag-lbl--inactive\n          fc-white fs-12 bg-yellow uppercase\n        "
                    },
                    [_vm._v("\n        Buyout\n      ")]
                  )
                ]
              )
            : _vm._e()
        ])
      : _vm._e(),
    _vm.currentFlags && _vm.invoice.flagged
      ? _c("div", { staticClass: "column mb-25" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase mb-10" }, [
            _vm._v("FLAGS")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            _vm._l(_vm.currentFlags, function(flag, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass:
                    "\n          InvoiceDetailsSidebarFlags__flag-wrapper row row--align-center row--justify-center\n          row--width-auto bg-red mr-6 mb-6 clickable\n        ",
                  attrs: { role: "button", tabindex: "0" },
                  on: {
                    click: function($event) {
                      return _vm.showSidebarNotify(flag)
                    }
                  }
                },
                [
                  _c(
                    "label",
                    {
                      staticClass:
                        "InvoiceDetailsSidebarFlags__flag-lbl fc-white fs-12 uppercase"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.flagDisplayName[flag] || flag) +
                          "\n        "
                      )
                    ]
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    _vm.previousFlags
      ? _c("div", { staticClass: "column mb-25" }, [
          _c("label", { staticClass: "fc-light fs-12 uppercase mb-10" }, [
            _vm._v("PREVIOUS FLAGS")
          ]),
          _c(
            "div",
            { staticClass: "row row--wrap" },
            _vm._l(_vm.previousFlags, function(flag, index) {
              return _c(
                "label",
                {
                  key: index,
                  staticClass:
                    "InvoiceDetailsSidebarFlags__previous-flag-lbl fs-12 uppercase bg-red mr-6 mb-6"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.flagDisplayName[flag] || flag) +
                      "\n      "
                  )
                ]
              )
            }),
            0
          )
        ])
      : _vm._e(),
    (_vm.currentFlags && _vm.invoice.flagged) ||
    (_vm.previousFlags && _vm.invoice.flagged)
      ? _c("hr", { staticClass: "Divider" })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }