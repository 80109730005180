<template>
  <div class="InvoiceDetailsSidebarFlag">
    <transition
      mode="out-in"
      name="Transition__fade"
    >
      <div
        v-if="!notifyView && !flagView"
        class="column"
        key="base"
      >
        <label class="InvoiceDetailsSidebarFlag__heading fs-18">Select Reason for Flagging</label>
        <base-flag
          @toggle="toggleFlag($event, 'missing bol')"
          :active="dataReason === 'missing bol'"
        >
          Missing BOL
        </base-flag>

        <base-flag
          @toggle="toggleFlag($event, 'missing rate con')"
          :active="dataReason === 'missing rate con'"
        >
          Missing Rate Con
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'missing signature')"
          :active="dataReason === 'missing signature'"
        >
          Missing Signature
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'rate con/bol mismatch')"
          :active="dataReason === 'rate con/bol mismatch'"
        >
          Rate Con/BOL Mismatch
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'rate incorrect')"
          :active="dataReason === 'rate incorrect'"
        >
          Rate Incorrect
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'unreadable')"
          :active="dataReason === 'unreadable'"
        >
          Unreadable
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'advance taken')"
          :active="dataReason === 'advance taken'"
        >
          Advance Taken
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'load not delivered')"
          :active="dataReason === 'load not delivered'"
        >
          Load Not Delivered
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'pending possible claim')"
          :active="dataReason === 'pending possible claim'"
        >
          Possible Claim
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'pending existing claim')"
          :active="dataReason === 'pending existing claim'"
        >
          Existing Claim
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'client balance')"
          :active="dataReason === 'client balance'"
        >
          Client Balance
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'pending low credit rating')"
          :active="dataReason === 'pending low credit rating'"
        >
          Low Credit Rating
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'wrong debtor')"
          :active="dataReason === 'wrong debtor'"
        >
          Wrong Debtor
        </base-flag>
        <base-flag
          @toggle="toggleFlag($event, 'pending other')"
          :active="dataReason === 'pending other'"
        >
          Other
        </base-flag>

        <base-textarea
          v-show="dataReason"
          v-model.trim="dataNote"
          class="width-100 mb-30"
          :label="true"
          :label-for="'notes'"
        >
          ADD NOTES?
        </base-textarea>

        <div class="row">
          <base-button
            @click="$emit('cancel')"
            class="bg-light fc-white mr-10"
            :disabled="buttonsDisabled"
          >
            Cancel
          </base-button>
          <base-button
            @click="flag"
            class="bg-blue fc-white"
            :disabled="!dataReason || buttonsDisabled"
          >
            Flag Invoice
          </base-button>
        </div>
      </div>

      <div
        v-else
        key="flagged"
      >
        <transition
          mode="out-in"
          name="Transition__opacity-fade"
        >
          <invoice-details-sidebar-notify
            v-if="notifyView"
            @cancel="noNotify"
            @notified="notify"
            key="notify"
          />

          <div
            v-if="flagView"
            class="column column--align-center"
            key="flagged"
          >
            <div class="InvoiceDetailsSidebarFlag__flag-mumbo-jumbo mb-23">
              <i class="fa fa-flag fa--none" />
            </div>
            <label class="fs-18 mb-4">
              {{ notified ? 'Invoice flagged and client notified' : 'Invoice flagged' }}
            </label>
            <div class="row row--justify-center">
              <base-button
                @click="$emit('cancel')"
                class="bg-light fc-white mr-10"
              >
                Back to Invoice details
              </base-button>
              <base-button
                @click="goToNextInvoice"
                class="bg-blue fc-white mr-10"
              >
                Next Invoice
              </base-button>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
// Packages
import moment from 'moment'
// Helpers
import {
  Invoice,
  InvoiceUpdate
} from '../../../utils/api'
import { goToNextInvoice } from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseFlag from '../../../components/base-flag.vue'
import BaseTextarea from '../../../components/base-textarea.vue'
import InvoiceDetailsSidebarNotify from './invoice-details-sidebar-notify.vue'

export default {
  name: 'InvoiceDetailsSidebarFlag',

  components: {
    BaseButton,
    BaseFlag,
    BaseTextarea,
    InvoiceDetailsSidebarNotify,
  },

  props: {
    note: {
      type: String,
      required: false,
      default: '',
    },
    reason: {
      type: String,
      required: false,
      default: null,
    },
  },

  data () {
    return {
      buttonsDisabled: false,
      flagView: false,
      dataNote: this.note,
      notified: false,
      notifyView: false,
      dataReason: this.reason,
    }
  },

  computed: {
    invoice () {
      return this.$store.getters.invoice
    },
  },

  methods: {
    alertVerified (invoice) {
      const recentMatchingUpdate = invoice.invoice_updates
        .find(update => update.update_status === this.dataReason.toLowerCase())
      this.$store.commit(
        'SET_ALERT',
        `This invoice was already flagged for "${this.dataReason}"
        by ${recentMatchingUpdate.user_name} at
        ${moment(recentMatchingUpdate.updated_at).format('M/D/YY, h:mma')}`
      )
    },

    async checkInvoiceStatus () {
      let invoiceCanBeFlagged = true

      // Before we flag, let's make sure it wasn't recently flagged for the same thing
      try {
        const thisInvoice = (await Invoice.get(this.invoice.id)).data
        if (
          (thisInvoice.invoice_updates && thisInvoice.invoice_updates.length)
          && thisInvoice.invoice_updates[0].update_status === this.dataReason
        ) {
          this.$store.commit('SET_ALERT', {
            message: `This invoice was already flagged for
              "${thisInvoice.invoice_updates[0].update_status}" by ${thisInvoice.invoice_updates[0].user_name}
              at ${moment(thisInvoice.invoice_updates[0].updated_at).format('M/D/YY, h:mma')}`,
            showNext: true,
            showPrevious: true,
          })
          this.buttonsDisabled = false
          this.progressFinish()
          return
        }

        if (thisInvoice.status !== 'pending') {
          this.$store.commit('SET_ALERT', {
            message: `This invoice is no longer pending. The last invoice update was
              by ${thisInvoice.invoice_updates[0].user_name}
              at ${moment(thisInvoice.invoice_updates[0].updated_at).format('M/D/YY, h:mma')}`,
            showNext: true,
            showPrevious: true,
          })
          this.buttonsDisabled = false
          this.progressFinish()
          return
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Flag "Check Invoice Status"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        invoiceCanBeFlagged = false
        this.buttonsDisabled = false
        this.requestFailure('There was an issue checking for recent identical updates', 'Request error')
      }

      return invoiceCanBeFlagged
    },

    async flag () {
      this.buttonsDisabled = true
      this.progressStart()
      window.scrollTo(0, 0)

      // If invoice is already currently flagged for this reason, don't flag; update Vuex
      if (!await this.checkInvoiceStatus()) return
      // Store invoice for immediate UI update in invoice-details
      this.storeFlaggedInvoice(await this.flagAPICall())
      // Store the selected flag reason for the notify view
      this.$store.commit('STORE_SIDEBAR_NOTIFY_REASON', this.dataReason)
      // Display the success messaging and UI actions
      this.buttonsDisabled = false
      this.notifyView = true
      this.progressFinish()
      this.requestSuccess({ message: 'The invoice was successfully flagged' })
    },

    async flagAPICall () {
      try {
        return (await InvoiceUpdate.create({
          invoice_id: this.invoice.id,
          notes: this.dataNote,
          update_status: this.dataReason,
          update_type: 'pending',
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Flag "Flag API Call"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue flagging the invoice' })
      }
    },

    async goToNextInvoice () {
      await goToNextInvoice(
        this.invoice.id,
        this.$router,
        (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
          message: 'There are no more invoices to review. How would you like to proceed?',
          showNext,
          showPrevious,
        }),
        'forward'
      )
    },

    notify () {
      this.notified = true
      this.flagView = true
      this.notifyView = false
    },

    noNotify () {
      this.flagView = true
      this.notifyView = false
    },

    storeFlaggedInvoice (flaggedUpdate) {
      // The result of this.flagAPICall is the INVOICE UPDATE not the updated invoice
      // We need to manipulate the invoice that gets sent to Vuex to show the user the
      // updated version of the invoice

      // This will ensure the invoice displays as flagged and invoice_updates is accurate
      // If an invoice was currently flagged, we need to add our reason to the flag reasons
      const user = JSON.parse(localStorage.getItem('user'))
      const invoice = Object.assign(this.invoice, {
        last_flag_message: this.invoice.flagged
          ? this.invoice.last_flag_message.concat(', ', flaggedUpdate.update_status)
          : flaggedUpdate.update_status,
        flagged: true,
        updates: [
          Object.assign(flaggedUpdate, {
            user_name: user.employee.first_name
          }),
          ...this.invoice.updates
        ],
      })
      // Store invoice for immediate UI update in invoice-details
      this.$store.commit('STORE_INVOICE', invoice)
    },

    toggleFlag (event, reason) {
      if (event.toggled) {
        this.dataReason = reason
      }
      else {
        this.dataReason = null
      }
    },
  },
}
</script>

<style lang='sass'>
.InvoiceDetailsSidebarFlag

  .BaseFlag
    margin-bottom: rem(20px)
    margin-right: rem(7px)

  .fa-flag
    color: $branding
    font-size: rem(40px)

  &__flag-mumbo-jumbo
    +flex($align: center, $justify: center)
    background-color: rgba(#1554D8, .1)
    border-radius: 50%
    height: rem(100px)
    width: rem(100px)

  &__heading
    margin-bottom: rem(50px)
</style>