var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoiceDetailsSidebarDecline" },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__fade" } },
        [
          !_vm.notifyView && !_vm.declinedView
            ? _c(
                "div",
                { key: "base", staticClass: "column" },
                [
                  _c(
                    "label",
                    {
                      staticClass: "InvoiceDetailsSidebarDecline__heading fs-18"
                    },
                    [_vm._v("\n        Select Reason for Declining\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.reason === "declined possible claim",
                        "data-cy": "invoice-details-decline-possible-claim-flag"
                      },
                      on: {
                        toggle: function($event) {
                          $event.toggled
                            ? (_vm.reason = "declined possible claim")
                            : (_vm.reason = null)
                        }
                      }
                    },
                    [_vm._v("\n        Possible Claim\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.reason === "declined existing claim",
                        "data-cy": "invoice-details-decline-existing-claim-flag"
                      },
                      on: {
                        toggle: function($event) {
                          $event.toggled
                            ? (_vm.reason = "declined existing claim")
                            : (_vm.reason = null)
                        }
                      }
                    },
                    [_vm._v("\n        Existing Claim\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.reason === "declined low credit rating",
                        "data-cy":
                          "invoice-details-decline-low-credit-rating-flag"
                      },
                      on: {
                        toggle: function($event) {
                          $event.toggled
                            ? (_vm.reason = "declined low credit rating")
                            : (_vm.reason = null)
                        }
                      }
                    },
                    [_vm._v("\n        Low Credit Rating\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.reason === "duplicate invoice",
                        "data-cy":
                          "invoice-details-decline-duplicate-invoice-flag"
                      },
                      on: {
                        toggle: function($event) {
                          $event.toggled
                            ? (_vm.reason = "duplicate invoice")
                            : (_vm.reason = null)
                        }
                      }
                    },
                    [_vm._v("\n        Duplicate Invoice\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.reason === "declined other",
                        "data-cy": "invoice-details-decline-other-flag"
                      },
                      on: {
                        toggle: function($event) {
                          $event.toggled
                            ? (_vm.reason = "declined other")
                            : (_vm.reason = null)
                        }
                      }
                    },
                    [_vm._v("\n        Other\n      ")]
                  ),
                  _c(
                    "base-textarea",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.reason,
                          expression: "reason"
                        }
                      ],
                      staticClass: "width-100 mb-30",
                      attrs: { label: true, "label-for": "notes" },
                      model: {
                        value: _vm.note,
                        callback: function($$v) {
                          _vm.note = typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "note"
                      }
                    },
                    [_vm._v("\n        ADD NOTES?\n      ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white mr-10",
                          attrs: { disabled: _vm.buttonsDisabled },
                          on: {
                            click: function($event) {
                              return _vm.$emit("cancel")
                            }
                          }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white",
                          attrs: {
                            "data-cy": "invoice-details-decline-btn",
                            disabled: !_vm.reason || _vm.buttonsDisabled
                          },
                          on: { click: _vm.decline }
                        },
                        [_vm._v("\n          Decline Invoice\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { key: "flagged" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        mode: "out-in",
                        name: "Transition__opacity-fade"
                      }
                    },
                    [
                      _vm.notifyView
                        ? _c("invoice-details-sidebar-notify", {
                            key: "notify",
                            on: { cancel: _vm.noNotify, notified: _vm.notify }
                          })
                        : _vm._e(),
                      _vm.declinedView
                        ? _c(
                            "div",
                            {
                              key: "flagged",
                              staticClass: "column column--align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "InvoiceDetailsSidebarDecline__flag-mumbo-jumbo mb-23"
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-add fa-rotate-45 danger fa-25 fa--none"
                                  })
                                ]
                              ),
                              _c("label", { staticClass: "fs-18 mb-4" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.notified
                                        ? "Invoice declined and client notified"
                                        : "Invoice Declined"
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "row row--justify-center" },
                                [
                                  _c(
                                    "base-button",
                                    {
                                      staticClass: "bg-blue fc-white mr-10",
                                      on: { click: _vm.goToNextInvoice }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Next Invoice\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }