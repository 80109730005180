var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoiceDetailsSidebarNotify column" },
    [
      _c("label", { staticClass: "fs-18 mb-12" }, [_vm._v("Notify client?")]),
      _c(
        "base-textarea",
        {
          staticClass: "width-100 mb-30",
          attrs: { label: true, "label-for": "message", rows: "5" },
          model: {
            value: _vm.message,
            callback: function($$v) {
              _vm.message = typeof $$v === "string" ? $$v.trim() : $$v
            },
            expression: "message"
          }
        },
        [_vm._v("\n    Message\n  ")]
      ),
      _c(
        "div",
        { staticClass: "row row--align-center" },
        [
          _c(
            "base-button",
            {
              staticClass: "bg-blue fc-white mr-10",
              on: {
                click: _vm.notify,
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.notify($event)
                }
              }
            },
            [_vm._v("\n      Yes\n    ")]
          ),
          _c(
            "base-button",
            {
              staticClass: "bg-light fc-white",
              on: {
                click: function($event) {
                  return _vm.$emit("cancel")
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.$emit("cancel")
                }
              }
            },
            [_vm._v("\n      No\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }