var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoiceDetailsNavigation row row--align-center" },
    [
      _c(
        "a",
        {
          staticClass:
            "\n      InvoiceDetailsNavigation__go-back-link row row--align-center fc-blue fs-20\n      fw-med capitalize ml-15\n    ",
          on: { click: _vm.goBack }
        },
        [
          _c("i", { staticClass: "fa fa-back fc-light fa-15" }),
          _vm._v(" Pending Invoices\n  ")
        ]
      ),
      _c("div", { staticClass: "row row--align-center row--justify-end" }, [
        _c(
          "button",
          {
            staticClass: "InvoiceDetailsNavigation__arrow-btn",
            attrs: { disabled: _vm.disabledNavigation || _vm.navigating },
            on: { click: _vm.getPreviousInvoice }
          },
          [
            _c("div", {
              staticClass: "InvoiceDetailsNavigation__arrow-btn--left"
            })
          ]
        ),
        _c(
          "button",
          {
            staticClass: "InvoiceDetailsNavigation__arrow-btn",
            attrs: { disabled: _vm.disabledNavigation || _vm.navigating },
            on: { click: _vm.getNextInvoice }
          },
          [
            _c("div", {
              staticClass: "InvoiceDetailsNavigation__arrow-btn--right"
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }