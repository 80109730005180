var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.invoice && _vm.invoice.id
    ? _c(
        "div",
        { staticClass: "InvoiceDetails column" },
        [
          _c("invoice-details-navigation", {
            attrs: { "disabled-navigation": _vm.disabledNavigation }
          }),
          _c("div", { staticClass: "InvoiceDetails__content-wrapper column" }, [
            _c(
              "div",
              { staticClass: "row row--wrap" },
              [
                _c(
                  "transition",
                  {
                    staticClass: "row",
                    attrs: {
                      name: "Transition__fade",
                      mode: "out-in",
                      tag: "div"
                    }
                  },
                  [
                    !_vm.editing
                      ? _c(
                          "div",
                          { key: "pdf", staticClass: "row" },
                          [
                            _c("invoice-details-sidebar", {
                              on: {
                                editing: function($event) {
                                  _vm.editing = true
                                }
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "column" },
                              [
                                _c("invoice-details-note-alert", {
                                  attrs: {
                                    "client-id": _vm.invoice.client_id,
                                    "debtor-id": _vm.invoice.debtor_id
                                  }
                                }),
                                _c("invoice-details-PDF", {
                                  key: _vm.invoice.id,
                                  attrs: {
                                    src: _vm.pdfSrc,
                                    updating: _vm.pdfUpdating
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editing
                      ? _c("base-invoice-create", {
                          key: "edit",
                          attrs: {
                            invoice: _vm.invoice,
                            "user-is-client": _vm.userIsClient
                          },
                          on: {
                            cancel: function($event) {
                              _vm.editing = false
                            },
                            updated: _vm.storeUpdatedInvoice
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("invoice-details-alert-modal")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }