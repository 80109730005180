<template>
  <div class="InvoiceDetailsSidebarNotifications">
    <!-- VERIFICATION -->
    <transition name="Transition__opacity-fade">
      <!-- An invoice needs verified and the debtor is NOT an unknown debtor -->
      <div
        v-if="invoice.requires_verification || manualVerification"
        class="column"
      >
        <label class="fs-16 fw-med mb-20">VERIFICATION REQUIRED</label>
        <div class="column mb-7">
          <base-input
            v-model.trim="inputs.talkedTo"
            :label="true"
            :label-for="'Talked-To'"
          >
            WHO DID YOU TALK TO?
          </base-input>
          <base-toggle
            @toggle="inputs.talkedToContactMethod = $event"
            class="mt-7"
            :toggles="options.talkedTo"
          />
        </div>
        <div class="column">
          <label class="fc-light fs-12 mb-7">SELECT AN OPTION</label>
          <v-select
            v-model="inputs.option"
            aria-label="Select an option"
            class="mb-20"
            :clearable="false"
            data-cy="invoice-details-sidebar-notifications-select"
            :options="options.verify"
            :placeholder="'Please select an option'"
          />
        </div>
        <base-textarea
          v-model.trim="inputs.notes"
          class="mb-12"
          :label="true"
          :label-for="'Notes'"
        >
          ANY NOTES?
        </base-textarea>

        <div class="row row--align-center">
          <!-- Is not disabled if bypass verification chosen -->
          <base-button
            @click="submitVerification"
            class="bg-blue fc-white mr-7"
            :disabled="submitVerificationDisabled"
            data-cy="invoice-details-sidebar-notifications-verify-submit-btn"
          >
            Submit
          </base-button>
          <base-button
            @click="$emit('trigger-flag-flow')"
            class="bg-light fc-white"
          >
            Flag
          </base-button>
        </div>
      </div>
    </transition>

    <hr
      v-if="invoice.requires_verification || manualVerification"
      class="Divider mt-30 mb-30"
    >

    <!-- DUPLICATION -->
    <div v-if="invoice.possible_duplicate">
      <div
        v-for="(duplicate, index) in possibleDuplicates"
        :key="index"
        class="
          InvoiceDetailsSidebarNotifications__container
          InvoiceDetailsSidebarNotifications__container--orange
          row
        "
      >
        <i class="fa fa-flag fa-15 fa-2" />
        <label class="InvoiceDetailsSidebarNotifications__label">Possible duplicate of
          <a
            @click="routeToInvoice(duplicate)"
            class="InvoiceDetailsSidebarNotifications__link"
          >
            #{{ duplicate.load_number }}
          </a>
        </label>
      </div>

      <hr class="Divider mt-30 mb-30">
    </div>
  </div>
</template>

<script>
// Helpers
import {
  Invoice,
  InvoiceUpdate
} from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseInput from '../../../components/base-input.vue'
import BaseTextarea from '../../../components/base-textarea.vue'
import BaseToggle from '../../../components/base-toggle.vue'

export default {
  name: 'InvoiceDetailsSidebarNotifications',

  components: {
    BaseButton,
    BaseInput,
    BaseTextarea,
    BaseToggle,
  },

  props: {
    manualVerification: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  async created () {
    // Is user a client?
    if (JSON.parse(localStorage.getItem('user')).client) {
      this.isUserClient = true
    }

    if (!this.invoice.possible_duplicate) {
      return
    }
    // Get possible duplicates
    try {
      this.possibleDuplicates = (await Invoice.getPossibleDuplicates({
        id: this.invoice.id,
        client_id: this.invoice.client.id,
        debtor_id: this.invoice.debtor.id,
        load_number: this.invoice.load_number,
      })).data
    }
    catch (error) {
      this.captureSentryEvent(
        'Sidebar Notifications "Created Get Possible Duplicates"',
        {
          config: error.config,
          data: this.$data,
          details: error,
          props: this.$props,
          response: error.response,
        }
      )
      this.CError(error)
      this.requestFailure({ message: 'There was an issue checking for duplicates' })
    }
  },

  data () {
    return {
      buttonsDisabled: false,
      inputs: {
        notes: '',
        option: null,
        talkedTo: '',
        talkedToContactMethod: 'phone',
      },
      isUserClient: false,
      options: {
        talkedTo: ['phone', 'email'],
        verify: [
          'verified',
          'waiting for response',
          'bypass verification'
        ],
      },
      possibleDuplicates: null,
    }
  },

  computed: {
    invoice () {
      // Do not load until data has been successfully fetch (avoids errors on slower connections)
      if (!this.$store.getters.invoice.id) {
        return false
      }
      return this.$store.getters.invoice
    },

    submitVerificationDisabled () {
      // disable it until they select an option
      if (!this.inputs.option) return true
      if (this.inputs.option === 'verified') {
        if (!this.inputs.talkedTo || this.buttonsDisabled) {
          return true
        }
      }
      // Bypass verification and waiting for response has no requirements
      return false
    }
  },

  methods: {
    async bypassVerification () {
      // Progress bar controlled in submitVerification
      this.buttonsDisabled = true
      try {
        const updatedInvoice = (await Invoice.update({
          id: this.invoice.id,
          requires_verification: false,
          skipped_verification: true, // Used to create a unique invoice update
        })).data
        // Store invoice for immediate UI update in invoice-details
        this.storeVerifiedInvoice(updatedInvoice)
        this.requestSuccess({ message: 'The invoice was successfully bypassed' })
        this.$emit('bypassed')
      }
      catch (error) {
        this.CError(error)
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue verifying the invoice' })
      }
    },

    routeToInvoice (duplicate) {
      const route = this.$router.resolve({ name: 'invoice-details', params: { id: duplicate.id } })
      window.open(route.href, '_blank')
    },

    storeNonVerifiedInvoice (update) {
      // However, GETting the invoice so soon only guarantees the invoice_updates will be
      // accurate 4/5 of the time; therefore, we'll manipulate the data to be consistent
      // (eventually consistent solution)

      // We need to ensure the invoice is flagged and invoice_updates is accurate
      const user = JSON.parse(localStorage.getItem('user'))
      const invoice = Object.assign(this.invoice, {
        last_flag_message: update.update_status,
        flagged: true,
        flagged_previously_pending: this.invoice.flagged,
        previous_flag_message: this.invoice.last_flag_message,
        updates: [
          Object.assign(update, {
            user_name: user.employee.first_name
          }),
          ...this.invoice.updates
        ],
      })
      this.$store.commit('STORE_INVOICE', invoice)
    },

    storeVerifiedInvoice (verifiedInvoice) {
      // Store invoice for immediate UI update in invoice-details
      this.$store.commit('STORE_INVOICE', verifiedInvoice)
    },

    async submitVerification () {
      // Backup check if user uses console to show button
      if (this.isUserClient) {
        return
      }

      this.buttonsDisabled = true
      this.progressStart()
      window.scrollTo(0, 0)

      /*
       * Types of verification
       * Verified: normal verification
       * Waiting for Response: makes invoice update, does not flag invoice, does not
       *   allow invoice to be processed (i.e. it's still awaiting verification)
       * Bypass Verification: makes invoice update, does not flag invoice, allows
       *   invoice to be processed
       */

      try {
        // If verified, update the invoice
        if (this.inputs.option === 'verified') await this.verify()

        if (this.inputs.option === 'waiting for response') await this.waitForResponse()

        if (this.inputs.option === 'bypass verification') await this.bypassVerification()

        this.buttonsDisabled = false
        this.progressFinish()
      }
      catch (error) {
        this.CError(error)
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue updating the invoice' })
      }
    },

    async updateAPICall (status) {
      try {
        // Update invoice
        return (await InvoiceUpdate.create({
          invoice_id: this.invoice.id,
          talked_to: this.inputs.talkedTo,
          talked_to_contact_method: this.inputs.talkedToContactMethod,
          notes: this.inputs.notes,
          update_status: status,
          update_type: 'pending',
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Notifications "Update API Call"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue updating the invoice' })
      }
    },

    async verifyAPICall () {
      try {
        return (await Invoice.update({
          id: this.invoice.id,
          notes: this.inputs.notes,
          requires_verification: false,
          talked_to: this.inputs.talkedTo,
          talked_to_contact_method: this.inputs.talkedToContactMethod,
          verified: 'verified'
        })).data
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Notifications "Verify API Call"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue verifying the invoice' })
      }
    },

    async verify () {
      // Progress bar controlled in submitVerification
      this.buttonsDisabled = true
      // Store invoice for immediate UI update in invoice-details
      this.storeVerifiedInvoice(await this.verifyAPICall())
      this.requestSuccess({ message: 'The invoice was successfully verified' })
      this.$emit('verified')
    },

    async waitForResponse () {
      // Progress bar controlled in submitVerification
      this.buttonsDisabled = true
      // Store invoice for immediate UI update in invoice-details
      this.storeNonVerifiedInvoice(await this.updateAPICall('waiting on verification'))
      this.requestSuccess({ message: 'Invoice updated' })
      this.$emit('waiting-for-response')
      // Reset inputs
      Object.assign(this.inputs, this.$options.data().inputs)
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsSidebarNotifications

  .BaseInput,
  .BaseTextarea,
  .v-select
    width: 100%

  &__buttons
    .BaseButton
      width: rem(104px)

      &:first-child
        margin-right: rem(10px)
</style>