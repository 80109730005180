var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notes.length
    ? _c(
        "div",
        {
          staticClass: "InvoiceDetailsNoteAlert bg-yellow clickable width-100",
          on: {
            click: function($event) {
              _vm.expanded = !_vm.expanded
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "InvoiceDetailsNoteAlert__top-container row row--align-center row--justify-between"
            },
            [
              _c("label", { staticClass: "fc-dark fs-16 fw-med" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.notes.length
                        ? "Processing Notes"
                        : "There are no notes for this invoice"
                    ) +
                    "\n    "
                )
              ]),
              _c("span", { staticClass: "fc-dark fs-16" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.expanded ? "[-]" : "[+]") + "\n    "
                )
              ])
            ]
          ),
          _c(
            "transition",
            { attrs: { mode: "out-in", name: "Transition__fade" } },
            [
              _vm.expanded
                ? _c(
                    "div",
                    {
                      staticClass:
                        "InvoiceDetailsNoteAlert__bottom-container mt-23"
                    },
                    [
                      _vm.generalNotes.length
                        ? _c(
                            "div",
                            { staticClass: "column" },
                            _vm._l(_vm.generalNotes, function(note, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  class: [
                                    "column",
                                    {
                                      "mb-16":
                                        _vm.specificNote ||
                                        (index === 0 &&
                                          _vm.generalNotes.length === 2)
                                    }
                                  ]
                                },
                                [
                                  _c("h1", { staticClass: "fs-15 fw-med" }, [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          note.client
                                            ? note.client.shortened_name
                                            : note.debtor.name
                                        ) +
                                        "\n          "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row row--align-center row--width-auto"
                                    },
                                    [
                                      _c(
                                        "time",
                                        {
                                          staticClass:
                                            "fc-light fs-14 no-wrap mr-10",
                                          attrs: {
                                            datetime: _vm._f("datetime")(
                                              note.created_at,
                                              "YYYY-MM-DD hh:mm:ssZ"
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm._f("datetime")(
                                                  note.created_at,
                                                  "MMM D, YYYY"
                                                )
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass:
                                          "InvoiceDetailsNoteAlert__hr"
                                      }),
                                      _c("label", { staticClass: "fs-15" }, [
                                        _vm._v(_vm._s(note.notes))
                                      ])
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm.specificNote
                        ? _c("div", { staticClass: "column" }, [
                            _c("div", { staticClass: "column" }, [
                              _c("h1", { staticClass: "fs-15 fw-med" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.specificNote.client
                                        ? _vm.specificNote.client
                                            .shortened_name +
                                            " and " +
                                            _vm.specificNote.debtor.name
                                        : _vm.specificNote.debtor.name
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "row row--align-center row--width-auto"
                                },
                                [
                                  _c(
                                    "time",
                                    {
                                      staticClass:
                                        "fc-light fs-14 no-wrap mr-10",
                                      attrs: {
                                        datetime: _vm._f("datetime")(
                                          _vm.specificNote.created_at,
                                          "YYYY-MM-DD hh:mm:ssZ"
                                        )
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm._f("datetime")(
                                              _vm.specificNote.created_at,
                                              "MMM D, YYYY"
                                            )
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _c("div", {
                                    staticClass: "InvoiceDetailsNoteAlert__hr"
                                  }),
                                  _c("label", { staticClass: "fs-15" }, [
                                    _vm._v(_vm._s(_vm.specificNote.notes))
                                  ])
                                ]
                              )
                            ])
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }