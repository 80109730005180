<template>
  <div class="InvoiceDetailsPDF">
    <transition
      v-if="updating"
      name="Transition__opacity-fade"
    >
      <div class="row row--align-center">
        <label class="fs-30 center width-100">
          Updating Invoice...
        </label>
      </div>
    </transition>

    <iframe
      v-else
      class="InvoiceDetailsPDF__PDF"
      id="pdfIFrame"
      ref="pdfIFrame"
      :src="src"
      frameborder="0"
    />
  </div>
</template>

<script>
export default {
  name: 'InvoiceDetailsPDF',

  props: {
    src: {
      type: String,
      required: true,
    },
    updating: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  mounted () {
    this.watchIFrame()
  },

  watch: {
    src () {
      this.watchIFrame()
    }
  },

  methods: {
    watchIFrame () {
      // PDF is rendered in queue, which means it's not readily available,
      // so, we need to create a watcher for when the src for the PDF is finally delivered
      // Reload logic - to detect if the PDF is blank and start an interval if it is
      // We'll clear the interval once the PDF display is no longer blank
      const checkPDFLoadInterval = setInterval(() => {
        const iframe = this.$refs.pdfIFrame
        if (iframe && iframe.contentDocument && iframe.contentDocument.URL === 'about:blank') {
          iframe.src = iframe.src
        } else {
          clearInterval(checkPDFLoadInterval)
        }
      }, 500)
    }
  }
}
</script>

<style lang="sass">
.InvoiceDetailsPDF
  width: 100%

  &__PDF
    border: 0
    min-height: 100vh
    width: 100%

</style>