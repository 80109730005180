var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "InvoiceDetailsSidebarApprove",
      attrs: { "data-cy": "invoice-details-sidebar-approve" }
    },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__fade" } },
        [
          !_vm.approvedView
            ? _c(
                "div",
                { key: "base", staticClass: "column" },
                [
                  !_vm.deliveryChecksValidation
                    ? _c("div", { staticClass: "row mb-25" }, [
                        _c(
                          "label",
                          {
                            staticClass:
                              "InvoiceDetailsSidebarApprove__flag-lbl bg-yellow fc-white fs-12 uppercase"
                          },
                          [
                            _vm._v(
                              "\n          Update delivery options\n        "
                            )
                          ]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class: [
                        "column clickable",
                        {
                          "mb-25": _vm.clientBalance < 0
                        }
                      ],
                      attrs: { tabindex: "0" },
                      on: { click: _vm.goToClientProfile }
                    },
                    [
                      _c("label", { staticClass: "fc-light fs-12 uppercase" }, [
                        _vm._v("CLIENT")
                      ]),
                      _c("label", { staticClass: "fs-18 capitalize" }, [
                        _vm._v(_vm._s(_vm.invoice.client.shortened_name))
                      ]),
                      _c("label", { staticClass: "fs-14 capitailze" }, [
                        _vm._v("MC: " + _vm._s(_vm.invoice.client.mc))
                      ]),
                      _c("label", { staticClass: "fs-14 capitalize" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatPhoneNumber(_vm.invoice.client.phone)
                          )
                        )
                      ])
                    ]
                  ),
                  _vm.clientBalance < 0
                    ? [
                        _c(
                          "div",
                          {
                            staticClass:
                              "InvoiceDetailsSidebarApprove__amounts-wrapper column"
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "fc-light fs-12 uppercase mb-12" },
                              [_vm._v("BALANCE")]
                            ),
                            _c(
                              "label",
                              {
                                class: [
                                  "fc-light fs-18 mb-27",
                                  { "fc-red": _vm.clientBalance < 0 }
                                ],
                                attrs: {
                                  "data-cy":
                                    "invoice-details-sidebar-approve-balance-lbl"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD"
                                      }).format(_vm.balanceAmount)
                                    ) +
                                    "\n          "
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row row--align-center row--justify-between"
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "fc-light fs-12 uppercase" },
                                  [_vm._v("APPROVED")]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "fs-18",
                                    attrs: {
                                      "data-cy":
                                        "invoice-details-sidebar-approve-approved-lbl"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                          }).format(_vm.invoice.amounts.total)
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row row--align-center row--justify-between"
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "fc-light fs-12 uppercase" },
                                  [_vm._v("Factor Fee")]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "fs-18",
                                    attrs: {
                                      "data-cy":
                                        "invoice-details-sidebar-approve-factor-fee-lbl"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                          }).format(_vm.factorFee)
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "\n              InvoiceDetailsSidebarApprove__deduction-input-wrapper\n              row row--align-center row--justify-between\n            "
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "fc-light fs-12 uppercase" },
                                  [_vm._v("DEDUCTION")]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row row--align-center row--width-auto"
                                  },
                                  [
                                    _c("span", { staticClass: "fs-18" }, [
                                      _vm._v("- $")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.trim",
                                          value: _vm.adjustment,
                                          expression: "adjustment",
                                          modifiers: { trim: true }
                                        }
                                      ],
                                      class: [
                                        "InvoiceDetailsSidebarApprove__deduction-input fs-18",
                                        {
                                          "InvoiceDetailsSidebarApprove__deduction-input--error": !_vm.deductionValidation
                                        }
                                      ],
                                      attrs: {
                                        "data-cy":
                                          "invoice-details-sidebar-approve-deduction-input",
                                        placeholder: "0"
                                      },
                                      domProps: { value: _vm.adjustment },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.adjustment = $event.target.value.trim()
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row row--align-center row--justify-between"
                              },
                              [
                                _c(
                                  "label",
                                  { staticClass: "fc-light fs-12 uppercase" },
                                  [_vm._v("FINAL")]
                                ),
                                _c(
                                  "label",
                                  {
                                    staticClass: "fs-18",
                                    attrs: {
                                      "data-cy":
                                        "invoice-details-sidebar-approve-final-lbl"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                          }).format(_vm.approvedAmount)
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            !!_vm.invoice.client.payment_plan_details
                              ? _c("div", { staticClass: "column mt-21" }, [
                                  _c(
                                    "label",
                                    { staticClass: "fc-light fs-14" },
                                    [_vm._v("Payment Plan")]
                                  ),
                                  _c("label", { staticClass: "fs-14" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.invoice.client.payment_plan_details
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.pastWeekChargebacks.length
                              ? _c(
                                  "div",
                                  { staticClass: "column mt-27" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "fc-light fs-14 uppercase"
                                      },
                                      [_vm._v("Chargebacks this week")]
                                    ),
                                    _vm._l(_vm.pastWeekChargebacks, function(
                                      chargeback,
                                      index
                                    ) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _c(
                                            "time",
                                            {
                                              staticClass: "fs-14 mr-12",
                                              attrs: {
                                                datetime: _vm._f("datetime")(
                                                  chargeback.created_at,
                                                  "YYYY-MM-DD hh:mm:ssZ"
                                                )
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm._f("datetime")(
                                                      chargeback.created_at,
                                                      "ddd - MMM D, YYYY"
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "label",
                                            { staticClass: "fs-14" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    Intl.NumberFormat("en-US", {
                                                      style: "currency",
                                                      currency: "USD"
                                                    }).format(
                                                      chargeback.amount / 100
                                                    )
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    })
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.pastWeekChargebacks.length
                              ? _c("div", { staticClass: "column mt-27" }, [
                                  _c(
                                    "label",
                                    { staticClass: "fc-light fs-14 uppercase" },
                                    [_vm._v("No chargebacks this week")]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    : _vm._e(),
                  _c("hr", { staticClass: "Divider" }),
                  _c(
                    "div",
                    { staticClass: "column mb-25", attrs: { tabindex: "0" } },
                    [
                      _c(
                        "div",
                        { staticClass: "row row--align-center" },
                        [
                          _c(
                            "label",
                            { staticClass: "fc-light fs-12 uppercase mr-5" },
                            [_vm._v("DEBTOR")]
                          ),
                          _c("base-debtor-rating", {
                            attrs: {
                              rating: _vm.invoice.debtor.display_rating,
                              size: "small"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "fs-18 capitalize clickable",
                          on: { click: _vm.goToDebtorProfile }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.sanitizeAlgoliaNames(
                                  _vm.invoice.debtor_selected_name
                                )
                              ) +
                              "\n        "
                          )
                        ]
                      ),
                      _c("label", { staticClass: "fs-14 capitailze" }, [
                        _vm._v(
                          _vm._s(_vm.invoice.debtor.city) +
                            ", " +
                            _vm._s(_vm.invoice.debtor.state)
                        )
                      ]),
                      _c("label", { staticClass: "fs-14 capitalize" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatPhoneNumber(_vm.invoice.debtor.phone)
                          )
                        )
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "column mb-25" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "Transition__fade" } },
                        [
                          _vm.currentDeliveryReqs
                            ? _c("base-delivery-requirements", {
                                attrs: { debtor: _vm.currentDeliveryReqs },
                                on: { save: _vm.setCurrentDeliveryReqs }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-10",
                          attrs: {
                            disabled: !_vm.validation || _vm.buttonsDisabled,
                            "data-cy": "invoice-details-approve-btn"
                          },
                          on: { click: _vm.approve }
                        },
                        [_vm._v("\n          Approve\n        ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white",
                          attrs: { disabled: _vm.buttonsDisabled },
                          on: { click: _vm.closeApproving }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            : _c(
                "div",
                { key: "flagged", staticClass: "column column--align-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "InvoiceDetailsSidebarFlag__flag-mumbo-jumbo mb-23"
                    },
                    [_c("i", { staticClass: "fa fa-check fa--none" })]
                  ),
                  _c("label", { staticClass: "fs-18 mb-4" }, [
                    _vm._v("Invoice approved")
                  ]),
                  _c(
                    "div",
                    { staticClass: "row row--justify-center" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white mr-10",
                          attrs: {
                            "data-cy":
                              "invoice-details-sidebar-approve-next-invoice-btn"
                          },
                          on: { click: _vm.goToNextInvoice }
                        },
                        [_vm._v("\n          Next Invoice\n        ")]
                      )
                    ],
                    1
                  )
                ]
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }