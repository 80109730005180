var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoiceDetailsSidebarFlag" },
    [
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__fade" } },
        [
          !_vm.notifyView && !_vm.flagView
            ? _c(
                "div",
                { key: "base", staticClass: "column" },
                [
                  _c(
                    "label",
                    { staticClass: "InvoiceDetailsSidebarFlag__heading fs-18" },
                    [_vm._v("Select Reason for Flagging")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "missing bol" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "missing bol")
                        }
                      }
                    },
                    [_vm._v("\n        Missing BOL\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "missing rate con" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "missing rate con")
                        }
                      }
                    },
                    [_vm._v("\n        Missing Rate Con\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "missing signature" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "missing signature")
                        }
                      }
                    },
                    [_vm._v("\n        Missing Signature\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.dataReason === "rate con/bol mismatch"
                      },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "rate con/bol mismatch")
                        }
                      }
                    },
                    [_vm._v("\n        Rate Con/BOL Mismatch\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "rate incorrect" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "rate incorrect")
                        }
                      }
                    },
                    [_vm._v("\n        Rate Incorrect\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "unreadable" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "unreadable")
                        }
                      }
                    },
                    [_vm._v("\n        Unreadable\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "advance taken" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "advance taken")
                        }
                      }
                    },
                    [_vm._v("\n        Advance Taken\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.dataReason === "load not delivered"
                      },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "load not delivered")
                        }
                      }
                    },
                    [_vm._v("\n        Load Not Delivered\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.dataReason === "pending possible claim"
                      },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag(
                            $event,
                            "pending possible claim"
                          )
                        }
                      }
                    },
                    [_vm._v("\n        Possible Claim\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.dataReason === "pending existing claim"
                      },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag(
                            $event,
                            "pending existing claim"
                          )
                        }
                      }
                    },
                    [_vm._v("\n        Existing Claim\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "client balance" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "client balance")
                        }
                      }
                    },
                    [_vm._v("\n        Client Balance\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: {
                        active: _vm.dataReason === "pending low credit rating"
                      },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag(
                            $event,
                            "pending low credit rating"
                          )
                        }
                      }
                    },
                    [_vm._v("\n        Low Credit Rating\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "wrong debtor" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "wrong debtor")
                        }
                      }
                    },
                    [_vm._v("\n        Wrong Debtor\n      ")]
                  ),
                  _c(
                    "base-flag",
                    {
                      attrs: { active: _vm.dataReason === "pending other" },
                      on: {
                        toggle: function($event) {
                          return _vm.toggleFlag($event, "pending other")
                        }
                      }
                    },
                    [_vm._v("\n        Other\n      ")]
                  ),
                  _c(
                    "base-textarea",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.dataReason,
                          expression: "dataReason"
                        }
                      ],
                      staticClass: "width-100 mb-30",
                      attrs: { label: true, "label-for": "notes" },
                      model: {
                        value: _vm.dataNote,
                        callback: function($$v) {
                          _vm.dataNote =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "dataNote"
                      }
                    },
                    [_vm._v("\n        ADD NOTES?\n      ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-light fc-white mr-10",
                          attrs: { disabled: _vm.buttonsDisabled },
                          on: {
                            click: function($event) {
                              return _vm.$emit("cancel")
                            }
                          }
                        },
                        [_vm._v("\n          Cancel\n        ")]
                      ),
                      _c(
                        "base-button",
                        {
                          staticClass: "bg-blue fc-white",
                          attrs: {
                            disabled: !_vm.dataReason || _vm.buttonsDisabled
                          },
                          on: { click: _vm.flag }
                        },
                        [_vm._v("\n          Flag Invoice\n        ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { key: "flagged" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        mode: "out-in",
                        name: "Transition__opacity-fade"
                      }
                    },
                    [
                      _vm.notifyView
                        ? _c("invoice-details-sidebar-notify", {
                            key: "notify",
                            on: { cancel: _vm.noNotify, notified: _vm.notify }
                          })
                        : _vm._e(),
                      _vm.flagView
                        ? _c(
                            "div",
                            {
                              key: "flagged",
                              staticClass: "column column--align-center"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "InvoiceDetailsSidebarFlag__flag-mumbo-jumbo mb-23"
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-flag fa--none"
                                  })
                                ]
                              ),
                              _c("label", { staticClass: "fs-18 mb-4" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.notified
                                        ? "Invoice flagged and client notified"
                                        : "Invoice flagged"
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "row row--justify-center" },
                                [
                                  _c(
                                    "base-button",
                                    {
                                      staticClass: "bg-light fc-white mr-10",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("cancel")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Back to Invoice details\n            "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "base-button",
                                    {
                                      staticClass: "bg-blue fc-white mr-10",
                                      on: { click: _vm.goToNextInvoice }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Next Invoice\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }