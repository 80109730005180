var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
    _vm.alert.visible
      ? _c("div", { staticClass: "InvoiceDetailsAlertModal__modal" }, [
          _c("div", { staticClass: "InvoiceDetailsAlertModal__modal-bg" }),
          _c(
            "div",
            { staticClass: "InvoiceDetailsAlertModal__modal-content column" },
            [
              _c("label", { staticClass: "fs-20 fw-med mb-21" }, [
                _vm._v("\n        " + _vm._s(_vm.alert.message) + "\n      ")
              ]),
              _c(
                "div",
                { staticClass: "row mb-21" },
                [
                  !_vm.alert.show.previous || !_vm.alert.show.next
                    ? _c(
                        "base-button",
                        {
                          ref: "InvoiceDetailsAlertModal__default-btn",
                          staticClass:
                            "InvoiceDetailsAlertModal__modal-btn bg-light fc-white mr-10",
                          on: { click: _vm.hideAlert }
                        },
                        [_vm._v("\n          Hide Alert\n        ")]
                      )
                    : _vm._e(),
                  _c(
                    "base-button",
                    {
                      staticClass:
                        "InvoiceDetailsAlertModal__modal-btn bg-white fc-blue mr-10",
                      on: { click: _vm.exitInvoiceDetails }
                    },
                    [_vm._v("\n          Exit Invoice Details\n        ")]
                  ),
                  _vm.alert.show.previous
                    ? _c(
                        "base-button",
                        {
                          ref: "InvoiceDetailsAlertModal__default-btn",
                          class: [
                            "InvoiceDetailsAlertModal__modal-btn fc-white",
                            {
                              "bg-light mr-10": _vm.alert.show.next,
                              "bg-blue": !_vm.alert.show.next
                            }
                          ],
                          on: { click: _vm.goToPreviousInvoice }
                        },
                        [_vm._v("\n          Previous Invoice\n        ")]
                      )
                    : _vm._e(),
                  _vm.alert.show.next
                    ? _c(
                        "base-button",
                        {
                          ref: "InvoiceDetailsAlertModal__default-btn",
                          class: [
                            "InvoiceDetailsAlertModal__modal-btn bg-blue fc-white"
                          ],
                          on: { click: _vm.goToNextInvoice }
                        },
                        [_vm._v("\n          Next Invoice\n        ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }