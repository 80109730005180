<template>
  <div
    class="InvoiceDetailsSidebarBase"
    data-cy="invoice-details-sidebar-base"
  >
    <invoice-details-sidebar-flags @show-sidebar-notify="triggerFlow('notifying')" />

    <!-- CLIENT -->
    <div class="row mb-25">
      <div class="column">
        <label class="fc-light fs-12 uppercase">CLIENT</label>
        <label
          @click="goToClientProfile"
          class="fs-18 capitalize clickable"
          data-cy="invoice-details-sidebar-base-client-name-lbl"
          tabindex="0"
        >
          {{ invoice.client.shortened_name }}
        </label>
        <label class="fs-14 capitailze">MC: {{ invoice.client.mc }}</label>
        <label class="fs-14 capitalize">{{ formatPhoneNumber(invoice.client.phone) }}</label>
      </div>
      <div class="row row--justify-end">
        <button
          @click="$emit('editing')"
          class="bg-white fc-blue"
        >
          <i class="fa fa-edit fa-18 fc-blue" />
          Edit
        </button>
      </div>
    </div>

    <!-- DEBTOR -->
    <div class="column mb-25">
      <div class="row row--align-center">
        <label class="fc-light fs-12 uppercase mr-5">DEBTOR</label>
        <base-debtor-rating
          :rating="invoice.debtor.display_rating"
          :size="'small'"
        />
      </div>
      <label
        @click="goToDebtorProfile"
        :class="['fs-18 capitalize clickable', {
          'clickable':
            invoice.debtor.id !== 2
            && invoice.debtor.name.toLowerCase() !== 'debtor not found'
        }]"
        tabindex="0"
      >
        {{ sanitizeAlgoliaNames(invoice.debtor_selected_name) }}
      </label>
      <label class="fs-14 capitailze">{{ invoice.debtor.city }}, {{ invoice.debtor.state }}</label>
      <label class="fs-14 capitalize">{{ formatPhoneNumber(invoice.debtor.phone) }}</label>
    </div>

    <!-- LOAD and AMOUNT -->
    <div class="row mb-25">
      <div class="column">
        <label class="fc-light fs-12 uppercase">LOAD #</label>
        <label class="fs-18 capitalize">{{ invoice.load_number }}</label>
      </div>
      <div class="column">
        <label class="fc-light fs-12 uppercase">AMOUNT</label>
        <label class="fs-18 capitalize">
          {{
            Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(invoice.amounts.total)
          }}
        </label>
      </div>
    </div>

    <!-- TRANSFER METHOD -->
    <div class="column mb-25">
      <label class="fc-light fs-12 uppercase">TRANSFER METHOD</label>
      <template v-if="invoice.transfer_type === 'wire'">
        <label class="fs-18 capitalize">
          Wire
        </label>
      </template>
      <label
        v-else
        class="fs-18 uppercase"
      >
        {{ invoice.transfer_type }}
      </label>
    </div>

    <template v-if="!invoice.requires_verification">
      <!-- FLOW BUTTONS -->
      <div class="row">
        <base-button
          v-if="invoice.debtor.id !== 2
            && invoice.debtor.name !== 'debtor not found'
            && invoice.client.is_active
          "
          @click="triggerFlow('approving')"
          class="bg-blue fc-white mr-10"
          data-cy="invoice-details-approve-btn"
          :disabled="invoice.requires_verification || approveButtonDisabled"
        >
          Approve
        </base-button>
        <base-button
          @click="triggerFlow('declining')"
          class="bg-light fc-white mr-10"
          data-cy="invoice-details-decline-btn"
          :disabled="buttonsDisabled"
        >
          Decline
        </base-button>
        <base-button
          @click="triggerFlow('flagging')"
          class="bg-white fc-light"
          :disabled="buttonsDisabled"
        >
          <i class="fa fa-flag" />
          Flag
        </base-button>
        <base-button
          @click="manualVerification = !manualVerification"
          class="bg-white fc-light ml-10"
          data-cy="invoice-details-sidebar-base-verify-btn"
        >
          <i class="fa fa-phone" />
          Verify
        </base-button>
      </div>
    </template>

    <!-- DELETE BTN -->
    <div class="row mt-30">
      <button
        @click="deleting = true"
        class="fc-light fs-14 uppercase"
      >
        <i class="fa fa-trash-can" />
        DELETE THIS INVOICE
      </button>
    </div>

    <!-- DELETE MODAL -->
    <base-item-delete
      v-if="deleting"
      @cancel="deleting = false"
      @delete="deleteInvoice"
      :invoice="invoice"
    />

    <transition
      mode="out-in"
      name="Transition__fade"
    >
      <div
        v-if="
          // Regardless of verification status and debtor ID, if the invoice is a possible
          // duplicate or someone has clicked 'verify', display the notification section.
          (invoice.requires_verification && invoice.debtor.id !== 2)
            || invoice.possible_duplicate
            || manualVerification
        "
      >
        <hr class="Divider">

        <invoice-details-sidebar-notifications
          @bypassed="manualVerification = false"
          @trigger-flag-flow="triggerFlow('flagging')"
          @verified="manualVerification = false"
          @waiting-for-response="manualVerification = false"
          :manual-verification="manualVerification"
        />
      </div>
    </transition>

    <!--
      This displays if invoice-details-sidebar-notifications does not output a final HR, which is
      the case when an invoice is not a duplicate, does not require verification, and someone did
      not choose to manually verify the invoice.

      As long as all this is true, so far, we only want to display it when the debtor is
      "debtor not found."
    -->
    <hr
      v-if="!invoice.requires_verification
        && !manualVerification
        && !invoice.possible_duplicate
      "
      class="Divider"
    >

    <invoice-updates
      :stack="true"
      :updates="invoice.updates"
    />
  </div>
</template>

<script>
// Helpers
import { Invoice } from '../../../utils/api'
import {
  debounce,
  formatPhoneNumber,
  goToNextInvoice,
  sanitizeAlgoliaNames
} from '../../../utils/helpers'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseDebtorRating from '../../../components/base-debtor-rating.vue'
import BaseItemDelete from '../../../components/base-item-delete.vue'
import InvoiceDetailsSidebarFlags from './invoice-details-sidebar-flags.vue'
import InvoiceDetailsSidebarNotifications from './invoice-details-sidebar-notifications.vue'
import InvoiceUpdates from '../../../components/invoice-updates.vue'

export default {
  name: 'InvoiceDetailsSidebarBase',

  components: {
    BaseButton,
    BaseDebtorRating,
    BaseItemDelete,
    InvoiceDetailsSidebarFlags,
    InvoiceDetailsSidebarNotifications,
    InvoiceUpdates,
  },

  created () {
    // Is user a client?
    if (JSON.parse(window.localStorage.getItem('user')).client) {
      this.isUserClient = true
    }
  },

  data () {
    return {
      approveButtonDisabled: false,
      buttonsDisabled: false,
      deleting: false,
      isUserClient: false,
      manualVerification: false,
    }
  },

  watch: {
    invoice (newInvoice, oldInvoice) {
      if (newInvoice.id !== oldInvoice.id) {
        this.approveButtonDisabled = false
        this.buttonsDisabled = false
        this.deleting = false
        this.manualVerification = false
      }
    },
  },

  computed: {
    invoice () {
      return this.$store.getters.invoice
    },
  },

  methods: {
    debounce,

    async deleteInvoice () {
      this.approveButtonDisabled = true
      this.buttonsDisabled = true

      this.progressStart()
      try {
        await Invoice.delete(this.invoice.id)
        this.requestSuccess({ message: 'Invoice successfully deleted' })
        await goToNextInvoice(
          this.invoice.id,
          this.$router,
          (backToPending, showPrevious, showNext) => this.$store.commit('SET_ALERT', {
            message: 'There are no more invoices to review. How would you like to proceed?',
            showNext,
            showPrevious,
          }),
          'forward'
        )
      }
      catch (error) {
        this.captureSentryEvent(
          'Sidebar Base "Invoice Delete"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.approveButtonDisabled = false
        this.buttonsDisabled = false
        this.requestFailure({ message: 'There was an issue deleting this invoice' })
      }
    },

    // Remove <em></em> from debtor name
    // https://app.zenhub.com/workspaces/bobtail-5c64d9b0b66dba3bb32bda6e/issues/fs-bobtail/bobtail/1694
    sanitizeAlgoliaNames,

    formatPhoneNumber,

    goToClientProfile () {
      if (['prod', 'prodlocal', 'staging'].includes(process.env.VUE_APP_ENV)) {
        // On production and staging, open in new tab
        const route = this.$router.resolve({
          name: 'client',
          params: {
            id: this.invoice.client.id.toString(),
          },
        })
        window.open(route.href, '_blank')
      } else {
        // On development, don't because it fucks with SO many tests
        this.$router.push({
          name: 'client',
          params: {
            id: this.invoice.client.id.toString(),
          },
        })
      }
    },

    goToDebtorProfile () {
      if (
        this.invoice.debtor.id === 2
        || this.invoice.debtor.name.toLowerCase() === 'debtor not found'
      ) return
      if (['prod', 'prodlocal', 'staging'].includes(process.env.VUE_APP_ENV)) {
        // On production and staging, open in new tab
        const route = this.$router.resolve({
          name: 'debtor',
          params: {
            id: this.invoice.debtor.id.toString(),
          },
        })
        window.open(route.href, '_blank')
      } else {
        // On development, don't because it fucks with SO many tests
        this.$router.push({
          name: 'debtor',
          params: {
            id: this.invoice.debtor.id.toString(),
          },
        })
      }
    },

    triggerFlow (flow) {
      // Backup to prevent clients from doing dirty little tricks
      if (this.isUserClient) {
        return
      }
      if (flow === 'approving') {
        this.approveButtonDisabled = true
        // Can't go to approve if invoice needs verified
        if (this.invoice.requires_verification && !this.invoice.requires_verification) {
          this.alertWarning('Invoice requires verification', 'Not ready for approval')
          return
        }
        // Can't go to approve if client has status of 'pending' or 'inactive'
        if (['pending', 'inactive'].includes(this.invoice.client.status)) {
          this.alertWarning('Client status is "pending" or "inactive"', 'Not ready for approval')
          return
        }
      }
      this.$emit('trigger-flow', flow)
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetailsSidebarBase

  .InvoiceUpdates
    padding: 0

  .Divider
    margin: rem(30px) 0

</style>
