<template>
  <div
    v-if="invoice && invoice.id"
    class="InvoiceDetails column"
  >
    <invoice-details-navigation :disabled-navigation="disabledNavigation" />

    <div class="InvoiceDetails__content-wrapper column">
      <div class="row row--wrap">
        <transition
          name="Transition__fade"
          class="row"
          mode="out-in"
          tag="div"
        >
          <div
            v-if="!editing"
            key="pdf"
            class="row"
          >
            <invoice-details-sidebar @editing="editing = true" />
            <!-- :key will force invoice-details-PDF to re-render everytime the key value changes -->
            <div class="column">
              <invoice-details-note-alert
                :client-id="invoice.client_id"
                :debtor-id="invoice.debtor_id"
              />
              <invoice-details-PDF
                :key="invoice.id"
                :src="pdfSrc"
                :updating="pdfUpdating"
              />
            </div>
          </div>

          <base-invoice-create
            v-if="editing"
            @cancel="editing = false"
            @updated="storeUpdatedInvoice"
            key="edit"
            :invoice="invoice"
            :user-is-client="userIsClient"
          />
        </transition>
      </div>
    </div>

    <invoice-details-alert-modal />
  </div>
</template>

<script>
// Helpers
import { Invoice } from '../../../utils/api'
// Component imports
import BaseInvoiceCreate from '../../../components/base-invoice-create.vue'
import InvoiceDetailsAlertModal from './invoice-details-alert-modal.vue'
import InvoiceDetailsNavigation from './invoice-details-navigation.vue'
import InvoiceDetailsNoteAlert from './invoice-details-note-alert.vue'
import InvoiceDetailsPDF from './invoice-details-pdf.vue'
import InvoiceDetailsSidebar from './invoice-details-sidebar.vue'

export default {
  name: 'InvoiceDetails',

  components: {
    BaseInvoiceCreate,
    InvoiceDetailsAlertModal,
    InvoiceDetailsNavigation,
    InvoiceDetailsNoteAlert,
    InvoiceDetailsPDF,
    InvoiceDetailsSidebar,
  },

  props: {
    // Passed when search in LS is null/undefined (from 'search-is-null' in goToNextInvoice)
    disabledNavigation: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: [Number, String], // if reloaded, a String is used from the URL
      required: true
    },
    userIsClient: {
      type: Boolean,
      required: true,
    }
  },

  async created () {
    if (this.invoice) this.$store.commit('CLEAR_INVOICE')

    await this.getInvoice()
  },

  mounted () {
    // If you scroll further down in pending invoices and choose a result from the bottom
    // The browser keeps your scroll position, so reseting for that use case
    window.scrollTo(0, 0)
  },

  beforeDestroy () {
    this.$store.commit('CLEAR_INVOICE')

    // It should stop searching when you leave the page
    if (this.pdfInterval) clearInterval(this.pdfInterval)
  },

  data () {
    return {
      editing: false,
      oldPDFUrl: null,
      pdfInterval: null,
      pdfUpdating: false // hide the pdf after update
    }
  },

  watch: {
    async id () {
      await this.getInvoice()
    }
  },

  computed: {
    invoice () {
      return this.$store.getters.invoice
    },

    pdfSrc () {
      return `https://docs.google.com/gview?url=${this.invoice.full_document_url}&embedded=true`
    },
  },

  methods: {
    async getInvoice () {
      try {
        // GET INVOICE
        const invoice = (await Invoice.get(this.id)).data

        // STORE INVOICE
        this.$store.commit('STORE_INVOICE', invoice)

        // For debugging:
        this.CLog(
          `%c INVOICE-DETAILS NAV INFO::
            loading invoice:${invoice.id} (${invoice.status})
          `,
          'color: blue; font-size: 16px;'
        )

        // CHECK INVOICE STATUS; if not 'pending'; ALERT and BAIL
        if (invoice.status !== 'pending') {
          this.$store.commit('SET_ALERT', {
            message: 'Invoice is no longer pending. How would you like to proceed',
            showNext: true,
            showPrevious: true,
          })
          return
        }
      }
      catch (error) {
        this.captureSentryEvent(
          'Invoice Details "Get Invoice"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        if (error.response.status === 404) {
          this.requestFailure({
            message: `We did not find an invoice with that ID (${this.$route.params.id})`
          })
        } else {
          this.requestFailure({ message: 'There was an issue retreiving the invoice' })
        }
        this.$router.push({ name: 'invoices' })
      }
    },

    monitorPDFUpdate () {
      let iteration = 0
      try {
        // hacky temporary way to update PDF Url (ultimately replace w/ websockets)
        this.pdfInterval = setInterval(async () => {
          const invoice = (await Invoice.get(this.id)).data
          iteration += 1
          if (invoice.full_document_url !== this.oldPDFUrl || iteration === 36) {
            this.$store.commit('STORE_INVOICE', invoice)
            this.pdfUpdating = false
            clearInterval(this.pdfInterval)
          }
        }, 5000)
      } catch (error) {
        this.captureSentryEvent(
          'Invoice Details "Monitor PDF Update"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        clearInterval(this.pdfInterval)
      }
    },

    storeUpdatedInvoice (invoice) {
      this.$store.commit('STORE_INVOICE', invoice)
      this.editing = false
      this.pdfUpdating = true
      this.oldPDFUrl = invoice.full_document_url
      this.monitorPDFUpdate()
    },
  },
}
</script>

<style lang="sass">
.InvoiceDetails
  padding: 0 rem(10px) rem(10px)
  postiion: relative

  &__content-wrapper
    background-color: $white
    border-radius: $border-radius
    box-shadow: $container-shadow
    padding: rem(50px) rem(55px)
    position: relative

  @media #{$tablet-landscape-and-up}
    margin-top: 0

  @media #{$xlarge-and-up}
    padding: 0
</style>
