var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoiceDetailsPDF" },
    [
      _vm.updating
        ? _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
            _c("div", { staticClass: "row row--align-center" }, [
              _c("label", { staticClass: "fs-30 center width-100" }, [
                _vm._v("\n        Updating Invoice...\n      ")
              ])
            ])
          ])
        : _c("iframe", {
            ref: "pdfIFrame",
            staticClass: "InvoiceDetailsPDF__PDF",
            attrs: { id: "pdfIFrame", src: _vm.src, frameborder: "0" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }