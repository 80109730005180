var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "InvoiceDetailsSidebarNotifications" },
    [
      _c("transition", { attrs: { name: "Transition__opacity-fade" } }, [
        _vm.invoice.requires_verification || _vm.manualVerification
          ? _c(
              "div",
              { staticClass: "column" },
              [
                _c("label", { staticClass: "fs-16 fw-med mb-20" }, [
                  _vm._v("VERIFICATION REQUIRED")
                ]),
                _c(
                  "div",
                  { staticClass: "column mb-7" },
                  [
                    _c(
                      "base-input",
                      {
                        attrs: { label: true, "label-for": "Talked-To" },
                        model: {
                          value: _vm.inputs.talkedTo,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.inputs,
                              "talkedTo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "inputs.talkedTo"
                        }
                      },
                      [_vm._v("\n          WHO DID YOU TALK TO?\n        ")]
                    ),
                    _c("base-toggle", {
                      staticClass: "mt-7",
                      attrs: { toggles: _vm.options.talkedTo },
                      on: {
                        toggle: function($event) {
                          _vm.inputs.talkedToContactMethod = $event
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "column" },
                  [
                    _c("label", { staticClass: "fc-light fs-12 mb-7" }, [
                      _vm._v("SELECT AN OPTION")
                    ]),
                    _c("v-select", {
                      staticClass: "mb-20",
                      attrs: {
                        "aria-label": "Select an option",
                        clearable: false,
                        "data-cy":
                          "invoice-details-sidebar-notifications-select",
                        options: _vm.options.verify,
                        placeholder: "Please select an option"
                      },
                      model: {
                        value: _vm.inputs.option,
                        callback: function($$v) {
                          _vm.$set(_vm.inputs, "option", $$v)
                        },
                        expression: "inputs.option"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "base-textarea",
                  {
                    staticClass: "mb-12",
                    attrs: { label: true, "label-for": "Notes" },
                    model: {
                      value: _vm.inputs.notes,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.inputs,
                          "notes",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "inputs.notes"
                    }
                  },
                  [_vm._v("\n        ANY NOTES?\n      ")]
                ),
                _c(
                  "div",
                  { staticClass: "row row--align-center" },
                  [
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-blue fc-white mr-7",
                        attrs: {
                          disabled: _vm.submitVerificationDisabled,
                          "data-cy":
                            "invoice-details-sidebar-notifications-verify-submit-btn"
                        },
                        on: { click: _vm.submitVerification }
                      },
                      [_vm._v("\n          Submit\n        ")]
                    ),
                    _c(
                      "base-button",
                      {
                        staticClass: "bg-light fc-white",
                        on: {
                          click: function($event) {
                            return _vm.$emit("trigger-flag-flow")
                          }
                        }
                      },
                      [_vm._v("\n          Flag\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _vm.invoice.requires_verification || _vm.manualVerification
        ? _c("hr", { staticClass: "Divider mt-30 mb-30" })
        : _vm._e(),
      _vm.invoice.possible_duplicate
        ? _c(
            "div",
            [
              _vm._l(_vm.possibleDuplicates, function(duplicate, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass:
                      "\n        InvoiceDetailsSidebarNotifications__container\n        InvoiceDetailsSidebarNotifications__container--orange\n        row\n      "
                  },
                  [
                    _c("i", { staticClass: "fa fa-flag fa-15 fa-2" }),
                    _c(
                      "label",
                      {
                        staticClass: "InvoiceDetailsSidebarNotifications__label"
                      },
                      [
                        _vm._v("Possible duplicate of\n        "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "InvoiceDetailsSidebarNotifications__link",
                            on: {
                              click: function($event) {
                                return _vm.routeToInvoice(duplicate)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          #" +
                                _vm._s(duplicate.load_number) +
                                "\n        "
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              }),
              _c("hr", { staticClass: "Divider mt-30 mb-30" })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }